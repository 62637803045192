import { generateId, getQueryStr, toPath } from '@/utils/helpers.js'

/** Administration */
const urlGetTemplateRoutingDocuments = (params = {}) => {
  const { page = 1 } = params
  return `/json/document/template?page=${page}`
}

const urlCreateTemplateRoutingDocument = () => {
  return '/json/document/template/create'
}

const urlUpdateTemplateRoutingDocument = id => {
  return `/json/document/template/update/${id}`
}

const urlDeleteTemplateRoutingDocument = id => {
  return `/json/document/template/delete/${id}`
}

const urlCreateRouteDocument = () => {
  return '/json/document/template/route/create'
}

const urlUpdateRouteDocument = id => {
  return `/json/document/template/route/update/${id}`
}

const urlGetTemplateRoutingDocumentSingle = id => {
  return `/json/document/template/single/${id}`
}

const urlDeleteTemplateRouteDocument = id => {
  return `/json/document/template/route/delete/${id}`
}
const urlCreateDocumentTemplateRouteUser = () => {
  return '/json/document/template/route/user/create'
}

const urlDeleteDocumentTemplateRouteUser = id => {
  return `/json/document/template/route/user/delete/${id}`
}
/** Notifications */
const urlGetNotifications = () => '/json/notification'
const urlReadNotification = notificationId =>
  `/json/notification/read/${notificationId}`

/** File */
const baseUrl = window.location.origin
const urlMicrosoft = 'https://view.officeapps.live.com/op/embed.aspx?src=' // eslint-disable-line max-len
const urlMicrosoftPreview = filePath => {
  if (!filePath) return filePath

  return `${urlMicrosoft}${baseUrl}${toPath(filePath)}?cache=${generateId()}`
}

/* Jira Errors */
const urlJiraCreateTask = () =>
  `/api/bugTracker?apiKey=${import.meta.env.VITE_EDS_API_KEY}`
/* POST */
const urlUploadFiles = () => '/json/file/upload'
/* POST */
const urlDeleteFile = () => '/json/file/delete'

/** Users */
/* GET */
const urlUserData = () => '/json/user/me'
/* GET */
const urlUserList = query => {
  return `/json/user/getAll${getQueryStr(query)}`
}

/* GET */
const urlUserBirthdaysList = (params = {}) => {
  const { filters } = params
  return `/json/user/birthdays${getQueryStr(filters, {
    ampersand: false,
  })}`
}

/* GET */
const urlUserSingle = userId => `/json/user/singe/${userId}`
/* POST */
const urlUserCreate = () => '/json/user/create'
/* POST */
const urlUserUpdate = userId => `/json/user/update/${userId}`
/* GET */
const urlCompanyStructure = () => {
  return '/json/department/getEmployeesDirectory'
}
/* GET */
const urlChangeUser = userId => {
  return `/json/user/changeUser/${userId}`
}
/* GET */
const urlUserActivate = userId => `/json/user/activate/${userId}`
/* GET */
const urlUserDeactivate = userId => `/json/user/deactivate/${userId}`
/* POST*/
const urlUserAbsenceCreate = () => '/json/user/absence/create'
/* DELETE */
const urlUserAbsenceDelete = absenceId =>
  `/json/user/absence/delete/${absenceId}`
/* GET */
const urlUserSendToErm = userId => `/json/user/sendUserErm/${userId}`

/** Authorization */
/* POST */
const urlLoginFirst = () => '/auth/firstLogin'
/* POST */
const urlLoginSecond = () => '/auth/secondLogin'
/* POST */
const urlLogout = () => '/auth/logout'
/* POST */
const urlResetPasswordSendSms = () => '/auth/password/reset/sendSms'
/* POST */
const urlResetPassword = () => '/auth/password/reset/resetPassword'

/** Documents */
/* GET */
const urlDocumentSignatureList = query => {
  return `/json/document/signature${getQueryStr(query)}`
}
/* GET */
const urlDocumentAgreementList = query => {
  return `/json/document/agreement${getQueryStr(query)}`
}
/* GET */
const urlIncomingDocuments = query => {
  return `/json/document/agreement/getIncoming${getQueryStr(query)}`
}
/* GET */
const urlOutgoingDocuments = query => {
  return `/json/document/agreement/getOutgoing${getQueryStr(query)}`
}
/* GET */
const urlDocumentAgreementSingle = docId =>
  `/json/document/agreement/single/${docId}`
const urlDocumentSignatureSingle = docId =>
  `/json/document/signature/single/${docId}`
/* POST */
const urlDocumentAgreementCreate = () => '/json/document/agreement/create'
/* POST */
const urlDocumentSignatureCreate = () => '/json/document/signature/create'
/* POST */
const urlDocumentAgreementUpdate = docId =>
  `/json/document/agreement/update/${docId}`
const urlDocumentSignatureUpdate = docId =>
  `/json/document/signature/update/${docId}`
/* POST */
const urlSendForAgree = docId => `/json/document/agreement/approve/${docId}`
/* POST */
const urlSendForRevision = docId => `/json/document/agreement/revision/${docId}`
/* POST */
const urlBreakAgreed = docId => `/json/document/agreement/break/${docId}`
/* POST */
const urlSignatureBreak = docId => `/json/document/signature/cancel/${docId}`
/* GET */
const urlSearchDocuments = search => {
  return `/json/search/documents?search=${search}`
}
/* POST */
const urlDocumentReadSignature = () => {
  return '/json/document/signature/participant/certificate'
}
/* POST */
const urlDocumentSign = () => {
  return '/json/document/signature/participant/signDocument'
}
/* GET */
const urlDocumentSendToSign = documentId => {
  return `/json/document/agreement/sendAgreeForSignature/${documentId}`
}
const urlDocumentSendToAgree = documentId => {
  return `/json/document/signature/sendSignatureForAgree/${documentId}`
}
/* GET */
const urlDocumentSignatureProtocol = documentId => {
  return `/json/document/signature/participant/protocol/${documentId}`
}
/* GET */
const urlDocumentSignatureArchive = documentId => {
  return `/json/document/signature/participant/archive/${documentId}`
}
/* GET */
const urlGetDiiaData = documentId => {
  return `/json/document/signature/participant/signWithDiia/${documentId}`
}
const urlSignCreate = () => {
  return '/json/document/signature/participant/create'
}

/** Orders */
/* GET */
const urlOrderList = query => {
  return `/json/order${getQueryStr(query)}`
}
/* GET */
const urlOrderFamiliarization = query => {
  return `/json/order/familiarization/getList${getQueryStr(query)}`
}
/* GET */
const urlOrderSingle = orderId => `/json/order/single/${orderId}`
/* POST */
const urlCreateOrder = () => '/json/order/create'
/* POST */
const urlUpdateOrder = orderId => `/json/order/update/${orderId}`
/* POST */
const urlDeactivateOrder = orderId => {
  return `/json/order/deactivate/${orderId}`
}
/* GET */
const urlActivateOrder = orderId => `/json/order/activate/${orderId}`
/* GET */
const urlCheckExistOrder = () => '/json/order/checkExists'
/* GET */
const urlFamiliarizationAgree = orderId =>
  `/json/order/familiarization/agree/${orderId}`
/* POST */
const urlOrderAccessCreate = () => '/json/order/access/create'
/* POST */
const urlOrderAccessDelete = accessId => `/json/order/access/delete/${accessId}`
/* DELETE */
const urlOrderDelete = orderId => `/json/order/delete/${orderId}`
/* POST */
const urlOrderRelatedAdd = orderId => `/json/order/related/add/${orderId}`
/* DELETE */
const urlOrderRelatedDelete = orderId => `/json/order/related/delete/${orderId}`
/* GET */
const urlOrderHistory = orderId => `/json/history/order/${orderId}`
/* GET */
const urlFamiliarizationReportUser = query => {
  return `/json/order/report${getQueryStr(query)}`
}
const urlFamiliarizationReportDepartment = query => {
  return `/json/order/report/department${getQueryStr(query)}`
}

/** Tasks */
/* GET */
const urlIncomingTasks = query => {
  return `/json/task/incomingTasks${getQueryStr(query)}`
}
/* GET */
const urlOutgoingTasks = query => {
  return `/json/task/outgoingTasks${getQueryStr(query)}`
}
/* POST */
const urlCreateTask = () => '/json/task/create'
/* POST */
const urlTaskUpdate = taskId => `/json/task/update/${taskId}`
/* GET */
const urlTaskSingle = taskId => `/json/task/single/${taskId}`
/* POST */
const urlTaskCopy = taskId => `/json/task/copy/${taskId}`
/* POST */
const urlTaskUpdateExecutor = taskId => `/json/task/executor/${taskId}`
/* POST */
const urlTaskUpdateDeadline = taskId => `/json/task/deadline/${taskId}`
/* GET */
const urlTaskTakeToWork = taskId => `/json/task/takeToWork/${taskId}`
/* POST */
const urlTaskComplete = taskId => `/json/task/complete/${taskId}`
/* POST */
const urlTaskReject = taskId => `/json/task/reject/${taskId}`
/* DELETE */
const urlTaskDelete = taskId => `/json/task/delete/${taskId}`
/* POST CONTROL USER */
const urlGetControlUsers = query => {
  return `/json/task/control${getQueryStr(query)}`
}
const urlTaskCreateControlUser = () => '/json/task/controlUser/create'
const urlTaskDeleteControlUser = id => `/json/task/controlUser/delete/${id}`
/* POST */
/* Observer User */
const urlGetObserverUsers = query => {
  return `/json/task/observer${getQueryStr(query)}`
}
const urlTaskCreateObserverUser = () => '/json/task/observers/create'
const urlTaskDeleteObserverUser = id => `/json/task/observers/delete/${id}`
// related tasks
const urlUnrelatedTask = id => `/json/task/unrelated/${id}`
const urlCreateRelatedTask = id => `/json/task/related/${id}`
const urlTaskPrintedForm = type => `/json/task/listPrintedForm?type=${type}`
/** Directory */
/* GET */
const urlTemplateRouteAgreements = () =>
  '/json/list/documentTemplateRouteAgreements'

const urlTemplateRouteActions = () => {
  return '/json/list/documentTemplateRouteActions'
}

/* GET */
const urlDocumentTemplates = () => {
  return '/json/list/documentTemplates'
}

const urlDocumentTypes = () => '/json/list/documentTypes'
/* GET */
const urlDocumentStatuses = () => '/json/list/documentStatuses'
/* GET */
const urlTaskTypes = () => '/json/list/taskTypes'
/* GET */
const urlAllEntities = (
  filters = { 'filter[]': ['user', 'group' /* department */] }
) => {
  return `/json/list/entities${getQueryStr(filters)}`
}
/* GET */
const urlAccessTypes = () => '/json/list/orderAccessTypes'
/* GET */
const urlOrderTypes = () => '/json/list/orderTypes'
/* GET */
const urlOrderDirections = () => '/json/list/orderDirections'
/* GET */
const urlAllUsers = () => '/json/list/users'
/* GET */
const urlRoles = () => '/json/list/roles'
/* GET */
const urlGenders = () => '/json/list/genders'
/* GET */
const urlAbsenceTypes = () => '/json/list/userAbsenceTypes'
/* GET */
const urlTasks = () => '/json/list/tasks'

/** Contractor */
/* GET */
const urlContractorList = query => `/json/contractor${getQueryStr(query)}`
/* GET */
const urlContractorSingle = id => `/json/contractor/single/${id}`
/* POST */
const urlContractorCreate = () => '/json/contractor/create'
/* POST */
const urlContractorUpdate = contractorId =>
  `/json/contractor/update/${contractorId}`
/* POST*/
const urlContractorAddressCreate = () => '/json/contractor/address/create'
/* POST */
const urlContractorAddressUpdate = addressId =>
  `/json/contractor/address/update/${addressId}`
/* DELETE */
const urlContractorAddressDelete = addressId =>
  `/json/contractor/address/delete/${addressId}`
/* POST*/
const urlContractorPhoneCreate = () => '/json/contractor/phone/create'
/* POST */
const urlContractorPhoneUpdate = phoneId =>
  `/json/contractor/phone/update/${phoneId}`
/* DELETE*/
const urlContractorPhoneDelete = phoneId =>
  `/json/contractor/phone/delete/${phoneId}`
/* POST */
const urlContractorEmailCreate = () => '/json/contractor/email/create'
/* POST */
const urlContractorEmailUpdate = emailId =>
  `/json/contractor/email/update/${emailId}`
/* DELETE*/
const urlContractorEmailDelete = emailId =>
  `/json/contractor/email/delete/${emailId}`

const urlCompanyGroups = () => '/json/group/getAll'

/** Department */
/* GET */
const urlDepartmentList = () => '/json/department'
/* POST */
const urlDepartmentCreate = () => '/json/department/create'
/* POST */
const urlDepartmentUpdate = departmentId =>
  `/json/department/update/${departmentId}`
/* DELETE */
const urlDepartmentDelete = departmentId =>
  `/json/department/delete/${departmentId}`
/* GET */
const urlDepartmentSingle = departmentId =>
  `/json/department/single${departmentId}`
/* GET */
const urlDepartmentSetSupervisor = userId =>
  `/json/department/user/setSupervisor/${userId}`
/* DELETE */
const urlDepartmentRemoveSupervisor = userId =>
  `/json/department/user/deleteSupervisor/${userId}`
/* POST */
const urlUserChangeDepartment = userId =>
  `/json/department/user/changeDepartment/${userId}`
/* POST */
const urlDepartmentAddUser = userId => `/json/department/user/add/${userId}`
/* DELETE */
const urlDepartmentRemoveUser = userId =>
  `/json/department/user/delete/${userId}`

/** Correspondence */
/* GET */
const urlCorrespondenceList = query =>
  `/json/correspondence${getQueryStr(query)}`
/* GET */
const urlCorrespondenceSingle = correspondenceId =>
  `/json/correspondence/single/${correspondenceId}`
/* POST */
const urlCorrespondenceCreate = () => '/json/correspondence/create'
/* POST */
const urlCorrespondenceUpdate = correspondenceId =>
  `/json/correspondence/update/${correspondenceId}`
/* DELETE */
const urlCorrespondenceDelete = correspondenceId =>
  `/json/correspondence/delete/${correspondenceId}`
/* POST */
const urlCorrespondenceDelivered = correspondenceId =>
  `/json/correspondence/delivered/${correspondenceId}`
/* POST */
const urlCorrespondenceRelated = correspondenceId =>
  `/json/correspondence/related/${correspondenceId}`
/* POST */
const urlCorrespondenceUnrelated = correspondenceId =>
  `/json/correspondence/unrelated/${correspondenceId}`

/* POST */
const urlCorrespondenceCreateUser = () => '/json/correspondence/user/create'
const urlCorrespondenceDeleteUser = id =>
  `/json/correspondence/user/delete/${id}`

/** Client Access */
/* POST */
const urlClientAccessCreate = () =>
  '/json/document/signature/clientAccess/create'
/* DELETE */
const urlClientAccessDelete = clientAccessId =>
  `/json/document/signature/clientAccess/delete/${clientAccessId}`
/* GET */
const urlClientAccessSendCode = accessToken =>
  `/client/auth/route/${accessToken}`
/* POST */
const urlClientAccessInputCode = () => '/client/auth/inputCode'
/* GET */
const urlClientAccessDocument = () => '/client/document'
/* POST */
const urlClientAccessReadSignature = () => {
  return '/client/participant/certificate'
}
/* POST */
const urlClientAccessSignDocument = () => {
  return '/client/participant/signDocument'
}
/* GET */
const urlClientSignatureProtocol = () => {
  return '/client/participant/protocol'
}
/* GET */
const urlClientSignatureArchive = () => {
  return '/client/participant/archive'
}
const urlClientAccessGetDiiaData = () => {
  return '/client/participant/signWithDiia'
}
const urlClientAccessCreateSign = () => {
  return '/client/participant/create'
}
const urlClientAccessUploadFile = () => {
  return '/client/upload'
}

/** Audit */
/* GET */
const urlAuditList = query => {
  return `/json/audit${getQueryStr(query)}`
}
/* GET */
const urlAuditSingle = auditId => {
  return `/json/audit/single/${auditId}`
}
/* POST */
const urlAuditCreate = () => {
  return '/json/audit/create'
}
/* POST */
const urlAuditUpdate = auditId => {
  return `/json/audit/update/${auditId}`
}
/* GET */
const urlAuditTakeToWork = auditId => `/json/audit/statusInWork/${auditId}`
/* POST */
const urlAuditComplete = auditId => `/json/audit/statusCompleted/${auditId}`
/* POST */
const urlAuditFlawCreate = () => '/json/audit/flaw/create'
/* POST */
const urlAuditFlawUpdate = flawId => `/json/audit/flaw/update/${flawId}`
/* DELETE */
const urlAuditFlawDelete = flawId => `/json/audit/flaw/delete/${flawId}`
/* POST */
const urlAuditRecommendationCreate = () => {
  return '/json/audit/recommendation/create'
}
/* POST */
const urlAuditRecommendationSetStatus = auditId => {
  return `/json/audit/recommendation/setStatus/${auditId}`
}
/* POST */
const urlAuditRiskCreate = () => {
  return '/json/audit/flaw/risk/create'
}
/* DELETE */
const urlAuditRiskDelete = riskId => {
  return `/json/audit/flaw/risk/delete/${riskId}`
}

/** Other */
/* GET */
const urlGeneralSearch = search => {
  return `/json/generalSearch?search=${search}`
}

/* POST */
const urlCommentCreate = () => '/json/comment/create'
/* POST */
const urlCommentUpdate = commentId => `/json/comment/update/${commentId}`
/* DELETE */
const urlCommentDelete = commentId => `/json/comment/delete/${commentId}`

/* POST */
const urlNoteCreate = () => '/json/note/create'
/* POST */
const urlNoteUpdate = commentId => `/json/note/update/${commentId}`
/* DELETE */
const urlNoteDelete = commentId => `/json/note/delete/${commentId}`

// search order
const urlSearchOrder = value => `/json/order/getSearch?search=${value}`

export {
  /** Administration */
  urlGetTemplateRoutingDocuments,
  urlCreateTemplateRoutingDocument,
  urlUpdateTemplateRoutingDocument,
  urlDeleteTemplateRoutingDocument,
  urlGetTemplateRoutingDocumentSingle,
  urlCreateRouteDocument,
  urlUpdateRouteDocument,
  urlDeleteTemplateRouteDocument,
  urlCreateDocumentTemplateRouteUser,
  urlDeleteDocumentTemplateRouteUser,

  /** Notifications */
  urlGetNotifications,
  urlReadNotification,
  /** JIRA */
  urlJiraCreateTask,
  /** File */
  urlUploadFiles,
  urlDeleteFile,
  urlMicrosoftPreview,

  /** Users */
  urlUserData,
  urlUserCreate,
  urlUserUpdate,
  urlUserList,
  urlUserSingle,
  urlCompanyStructure,
  urlChangeUser,
  urlUserActivate,
  urlUserDeactivate,
  urlUserAbsenceCreate,
  urlUserAbsenceDelete,
  urlUserSendToErm,
  urlUserBirthdaysList,

  /** Authorization */
  urlLoginFirst,
  urlLoginSecond,
  urlLogout,
  urlResetPasswordSendSms,
  urlResetPassword,

  /** Documents */
  urlDocumentSignatureList,
  urlDocumentAgreementList,
  urlIncomingDocuments,
  urlOutgoingDocuments,
  urlDocumentAgreementSingle,
  urlDocumentSignatureSingle,
  urlDocumentAgreementCreate,
  urlDocumentSignatureCreate,
  urlDocumentAgreementUpdate,
  urlDocumentSignatureUpdate,
  urlSendForAgree,
  urlSendForRevision,
  urlBreakAgreed,
  urlSignatureBreak,
  urlSearchDocuments,
  urlDocumentReadSignature,
  urlDocumentSign,
  urlDocumentSendToSign,
  urlDocumentSendToAgree,
  urlDocumentSignatureProtocol,
  urlDocumentSignatureArchive,
  urlGetDiiaData,
  urlSignCreate,

  /** Orders */
  urlOrderList,
  urlOrderFamiliarization,
  urlOrderSingle,
  urlCreateOrder,
  urlUpdateOrder,
  urlDeactivateOrder,
  urlActivateOrder,
  urlCheckExistOrder,
  urlFamiliarizationAgree,
  urlOrderAccessCreate,
  urlOrderAccessDelete,
  urlOrderDelete,
  urlOrderRelatedAdd,
  urlOrderRelatedDelete,
  urlOrderHistory,
  urlFamiliarizationReportUser,
  urlFamiliarizationReportDepartment,

  /** Tasks */
  urlIncomingTasks,
  urlOutgoingTasks,
  urlCreateTask,
  urlTaskUpdate,
  urlTaskSingle,
  urlTaskCopy,
  urlTaskTakeToWork,
  urlTaskComplete,
  urlTaskUpdateExecutor,
  urlTaskUpdateDeadline,
  urlTaskReject,
  urlTaskDelete,
  urlTaskCreateControlUser,
  urlTaskDeleteControlUser,
  urlTaskCreateObserverUser,
  urlTaskDeleteObserverUser,
  urlUnrelatedTask,
  urlCreateRelatedTask,
  urlTaskPrintedForm,
  urlGetControlUsers,
  urlGetObserverUsers,

  /** Directory */
  urlDocumentTypes,
  urlTemplateRouteActions,
  urlTemplateRouteAgreements,
  urlDocumentStatuses,
  urlTaskTypes,
  urlAllEntities,
  urlAccessTypes,
  urlOrderTypes,
  urlOrderDirections,
  urlRoles,
  urlGenders,
  urlAllUsers,
  urlAbsenceTypes,
  urlDocumentTemplates,
  urlTasks,

  /** Contractor */
  urlContractorList,
  urlContractorSingle,
  urlCompanyGroups,
  urlContractorCreate,
  urlContractorUpdate,
  urlContractorAddressCreate,
  urlContractorAddressUpdate,
  urlContractorAddressDelete,
  urlContractorPhoneCreate,
  urlContractorPhoneUpdate,
  urlContractorPhoneDelete,
  urlContractorEmailCreate,
  urlContractorEmailUpdate,
  urlContractorEmailDelete,

  /** Department */
  urlDepartmentList,
  urlDepartmentCreate,
  urlDepartmentUpdate,
  urlDepartmentDelete,
  urlDepartmentSingle,
  urlDepartmentSetSupervisor,
  urlDepartmentRemoveSupervisor,
  urlUserChangeDepartment,
  urlDepartmentAddUser,
  urlDepartmentRemoveUser,

  /** Correspondence */
  urlCorrespondenceList,
  urlCorrespondenceSingle,
  urlCorrespondenceCreate,
  urlCorrespondenceUpdate,
  urlCorrespondenceDelete,
  urlCorrespondenceDelivered,
  urlCorrespondenceRelated,
  urlCorrespondenceUnrelated,
  urlCorrespondenceCreateUser,
  urlCorrespondenceDeleteUser,

  /** Client Access */
  urlClientAccessCreate,
  urlClientAccessDelete,
  urlClientAccessSendCode,
  urlClientAccessInputCode,
  urlClientAccessDocument,
  urlClientAccessReadSignature,
  urlClientAccessSignDocument,
  urlClientSignatureProtocol,
  urlClientSignatureArchive,
  urlClientAccessGetDiiaData,
  urlClientAccessCreateSign,
  urlClientAccessUploadFile,

  /** Audit */
  urlAuditList,
  urlAuditSingle,
  urlAuditCreate,
  urlAuditUpdate,
  urlAuditTakeToWork,
  urlAuditComplete,
  urlAuditFlawCreate,
  urlAuditFlawUpdate,
  urlAuditFlawDelete,
  urlAuditRecommendationCreate,
  urlAuditRecommendationSetStatus,
  urlAuditRiskCreate,
  urlAuditRiskDelete,

  /** Other */
  urlGeneralSearch,
  urlCommentCreate,
  urlCommentUpdate,
  urlCommentDelete,
  urlNoteCreate,
  urlNoteUpdate,
  urlNoteDelete,
  urlSearchOrder,
}
