<template>
  <div>
    <div>
      <Skeleton v-if="initLoading" />
      <div v-else>
        <div
          v-if="!createRoute"
          class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <div v-if="task.isOverdue" class="mr-2">
              <v-chip color="red" size="small">Прострочено</v-chip>
            </div>
            <span class="font-weight-bold d-block" style="font-size: 24px">{{
              !createRoute ? 'Завдання' : 'Нове завдання'
            }}</span>
          </div>
          <div class="d-flex align-center">
            <div class="d-flex align-center mr-2">
              <span class="font-weight-bold"
                >Статус: {{ task?.status?.name }}</span
              >
            </div>
            <div v-if="task.isHighImportance" class="d-flex align-center mr-2">
              <div style="background: #fff3d8; border-radius: 50%">
                <v-icon
                  class="pa-3"
                  color="#FF8800"
                  icon="mdi-check-circle-outline" />
              </div>
              <span class="font-weight-bold ml-2">Важлива задача</span>
            </div>
            <div v-if="task.entityTypeId">
              <v-tooltip
                :text="`${
                  task.entityTypeId === 3
                    ? 'Перейти в картку наказу'
                    : 'Перейти в картку кореспонденції'
                }`"
                location="bottom">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    @click="
                      navigateToItem(
                        task.entityTypeId === 3
                          ? 'single-order'
                          : 'correspondence-single',
                        { id: task.entityId }
                      )
                    ">
                    <v-icon>{{ 'mdi-link' }}</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
            <div class="ml-3">
              <TableActions
                v-if="!createRoute"
                :actions="actions.taskActions(task.$object, taskType)"
                is-menu />
            </div>
          </div>
        </div>
        <v-row class="align-end flex-wrap-reverse mt-4">
          <v-col cols="12" md="7" sm="12">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <b-input
                  v-model="task.topic"
                  :error-messages="getErrorMessages(v$.topic)"
                  :readonly="task.status && task.status.id !== 1"
                  hide-details
                  label="Тема задачі"
                  placeholder="Введіть текст"
                  @blur="v$.topic.$touch()" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <b-select
                  :items="$directory.get('taskTypes', task.type)"
                  :modelValue="task.typeId"
                  :readonly="true"
                  hide-details
                  label="Тип задачі"
                  placeholder="Оберіть зі списку" />
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <BaseDatePicker
                  v-model="task.deadline"
                  :error-messages="getErrorMessages(v$.deadline)"
                  :loading="$loading.isLoading('updateDeadline')"
                  :readonly="disabled || $loading.isLoading('updateDeadline')"
                  hide-details
                  label="Термін виконання"
                  time-picker
                  @blur="v$.deadline.$touch()"
                  @update:model-value="updateDeadline" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <b-select
                  v-model="task.authorId"
                  :error-messages="getErrorMessages(v$.authorId)"
                  :item-title="
                    u =>
                      getPersonName(u, { keyNames: { surname: 'secondName' } })
                  "
                  :items="$directory.get('userList', task.author)"
                  :loading="$loading.isLoading('userList')"
                  :readonly="!taskType.execution || disabled"
                  hide-details
                  item-value="id"
                  label="Видав"
                  placeholder="Оберіть зі списку"
                  @blur="v$.authorId.$touch()"
                  @focus="
                    () => {
                      if (!!taskType.execution && !disabled) {
                        $directory.fill('userList')
                      }
                    }
                  " />
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <b-select
                  v-if="!(taskType.familiarization && createRoute)"
                  v-model="task.executorId"
                  :error-messages="getErrorMessages(v$.executorId)"
                  :item-title="
                    u =>
                      getPersonName(u, { keyNames: { surname: 'secondName' } })
                  "
                  :items="$directory.get('userList', task.executor)"
                  :loading="$loading.isLoading('userList')"
                  :readonly="disabled"
                  hide-details
                  item-value="id"
                  label="Виконавець"
                  placeholder="Оберіть зі списку"
                  @blur="v$.executorId.$touch()"
                  @focus="!disabled && $directory.fill('userList')"
                  @update:model-value="updateExecutor" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-switch
                  v-if="!taskType.familiarization"
                  v-model="task.isHighImportance"
                  :readonly="disabled"
                  color="primary"
                  hide-details
                  label="Важлива задача"
                  single>
                </v-switch>
              </v-col>
            </v-row>
            <v-row
              v-if="taskType.execution && task.created_at"
              align-content="center"
              class="mb-4"
              justify="space-between">
              <v-col cols="12">
                <b-autocomplete
                  v-model="selectedUser"
                  :items="$directory.get('userList')"
                  :loading="$loading.isLoading('userList')"
                  :readonly="disabled || task.control_users.length >= 1"
                  hide-details
                  item-title="name"
                  item-value="id"
                  label="Контролюючий"
                  placeholder="Оберіть зі списку"
                  return-object
                  @focus="
                    () => {
                      if (!disabled && !(task.control_users.length >= 1)) {
                        $directory.fill('userList')
                      }
                    }
                  ">
                  <template #append>
                    <v-btn
                      :disabled="task.control_users.length >= 1"
                      color="secondary"
                      @click="handleAccessAddUser('control_users')">
                      <v-icon class="mr-1"
                        >{{ 'mdi-plus-box-multiple-outline' }}
                      </v-icon>
                      Додати
                    </v-btn>
                  </template>
                </b-autocomplete>
              </v-col>
              <v-col v-if="task.control_users.length" cols="12" md="12" sm="12">
                <CustomItem
                  v-for="user in task.control_users"
                  :key="user.id"
                  :item="user"
                  hidePost>
                  <template #actions>
                    <v-btn
                      :disabled="disabled"
                      icon
                      size="small"
                      variant="text"
                      @click="handleRemoveUser('control_users', user.id)">
                      <v-icon icon="mdi-delete" size="large" />
                    </v-btn>
                  </template>
                </CustomItem>
              </v-col>
            </v-row>
            <v-row
              v-if="!createRoute"
              align-content="center"
              class="mb-4"
              justify="space-between">
              <v-col cols="12">
                <b-autocomplete
                  v-model="selectedObServerUser"
                  :items="$directory.get('entityList')"
                  :loading="$loading.isLoading('entityList')"
                  :readonly="disabled"
                  hide-details
                  item-title="name"
                  item-value="id"
                  label="Наглядач"
                  placeholder="Оберіть зі списку"
                  return-object
                  @focus="
                    () => {
                      if (!disabled) {
                        $directory.fill({
                          name: 'entityList',
                          query: { filter: ['user', 'department'] },
                          force: true,
                        })
                      }
                    }
                  ">
                  <!-- TODO - best-modules fix Query type -->
                  <template v-if="!disabled" #append>
                    <v-btn
                      color="secondary"
                      @click="handleAccessAddUser('observers')">
                      <v-icon class="mr-1">
                        {{ 'mdi-plus-box-multiple-outline' }}
                      </v-icon>
                      Додати
                    </v-btn>
                  </template>
                </b-autocomplete>
              </v-col>
              <v-col
                v-if="task.observers && task.observers.length"
                cols="12"
                md="12"
                sm="12">
                <CustomItem
                  v-for="user in task.observers"
                  :key="user.id"
                  :item="user">
                  <template #actions>
                    <v-btn
                      :disabled="disabled"
                      icon
                      size="small"
                      variant="text"
                      @click="handleRemoveUser('observers', user.id)">
                      <v-icon icon="mdi-delete" size="large" />
                    </v-btn>
                  </template>
                </CustomItem>
              </v-col>
            </v-row>
            <v-row v-if="taskType.execution || taskType.errand">
              <v-col cols="12" md="12" sm="12">
                <b-autocomplete
                  v-model="task.contractorId"
                  :items="$directory.get('contractorList', task.contractor)"
                  :loading="$loading.isLoading('contractorList')"
                  :readonly="disabled"
                  hide-details
                  item-title="name"
                  item-value="id"
                  label="Контрагент"
                  placeholder="Оберіть зі списку"
                  showClear
                  @focus="
                    () => {
                      if (!disabled) {
                        $directory.fill('contractorList')
                      }
                    }
                  " />
              </v-col>
            </v-row>
            <v-row v-if="taskType.familiarization && createRoute">
              <v-col cols="12" md="12" sm="12">
                <SelectEntities
                  v-model:entities="task.accessEntities"
                  :readonly="disabled"
                  :v$="v$.accessEntities"
                  label="Кому" />
              </v-col>
            </v-row>
            <v-row v-if="!taskType.errand">
              <v-col cols="12" md="12" sm="12">
                <BaseTextarea
                  v-model="task.description"
                  :error-messages="getErrorMessages(v$.description)"
                  :readonly="disabled"
                  hide-details
                  label="Опис задачі"
                  placeholder="Введіть текст"
                  @blur="v$.description.$touch()" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5" sm="12">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <FilesUploader
                  v-model="task.attachment"
                  :label="taskType.execution ? 'Вкладення' : 'Файли наказу'"
                  :name-folder="
                    createRoute ? 'template' : task.created_at.substring(6, 10)
                  "
                  :prefix-folder="
                    createRoute ? void [0] : task.created_at.substring(3, 5)
                  "
                  :readonly="disabled"
                  :v$="v$.attachment"
                  dropper
                  main-folder="Документы"
                  outline />
              </v-col>
            </v-row>
            <v-row v-if="!createRoute">
              <v-col cols="12" md="12" sm="12">
                <Comments
                  :comments="task.comments"
                  :disabled="disabled"
                  :entityId="task.id"
                  :entityTypeId="1" />
              </v-col>
            </v-row>
            <v-row v-if="!createRoute && task.completedDate" class="mt-5">
              <v-col cols="12" md="12" sm="12">
                <div class="mb-3">
                  <span class="font-weight-bold"> Дата завершення: </span>
                  <span class="text-disabled">{{ task.completedDate }}</span>
                </div>

                <div v-if="task.completionReport">
                  <div class="mb-2 font-weight-bold">Коментар завершення:</div>
                  {{ task.completionReport || task.rejectComment }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <SubmitButtons
      v-if="!disabled"
      :disabled="!createRoute && !task.$hasChanges"
      :submit="submit"
      :submitLabel="createRoute ? 'Створити' : 'Оновити'"
      class="mt-10 d-flex justify-end"
      compact
      @cancel="cancel" />
  </div>
</template>

<script lang="ts">
import SubmitButtons from '@/components/SubmitButtons.vue'
import Skeleton from '@/components/Skeleton.vue'
import SelectEntities from '@/components/entities/SelectEntities.vue'
import Comments from '@/components/Comments.vue'
import { useRoute } from '@/plugins'
import { computed, ref } from 'vue'
import { axios } from '@/plugins/index'
import { useEditTask } from '@/pages/tasks/editTask'
import { useCreateTask } from '@/pages/tasks/createTask'
import { useTaskActions } from '@/pages/tasks/taskActions.js'
import FilesUploader from '@/components/filesUploader/FilesUploader.vue'
import { getErrorMessages, getUserName, navigateToItem } from '@/utils/helpers'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import {
  urlTaskCreateControlUser,
  urlTaskCreateObserverUser,
  urlTaskDeleteControlUser,
  urlTaskDeleteObserverUser,
  urlTaskUpdateDeadline,
  urlTaskUpdateExecutor,
} from '@/utils/urls'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import CustomItem from '@/dialog/components/templateGroup/CustomItem.vue'
import TableActions from '@/components/TableActions.vue'
import { fillDirectory } from '@/directory'
import { getPersonName } from 'best-modules/utils/helpers'
import { socket, taskNotification } from '@/socket'
import { Task } from '@/utils/types/task'
import { omit } from 'lodash'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'

export default {
  components: {
    TableActions,
    CustomItem,
    BaseDatePicker,
    FilesUploader,
    SubmitButtons,
    Skeleton,
    SelectEntities,
    Comments,
  },
  props: {
    taskData: { type: Object, default: null },
  },
  methods: {
    getPersonName,
    fillDirectory,
    navigateToItem,
    getUserName,
    getErrorMessages,
  },
  setup(props) {
    const route = useRoute()
    const createRoute = computed(() => {
      return [
        'correspondence-create-task',
        'order-create-task',
        'create-task',
      ].includes(route.name as string)
    })
    const initLoading = ref(true)
    const selectedUser = ref(null)
    const selectedObServerUser = ref(null)
    const actions = useTaskActions()

    const useTask = createRoute.value
      ? useCreateTask(props.taskData)
      : useEditTask()
    const { task } = useTask as { task: CachedObjectProxy<Task> }

    const handleCreateTaskUser = (
      action: 'control_users' | 'observers',
      user
    ) => {
      const obj =
        action === 'control_users'
          ? {
              taskId: +route.params.id,
              userId: user?.id,
            }
          : {
              entityId: user.entityId,
              entityTypeId: user.entityTypeId,
              taskId: +route.params.id,
            }
      const url =
        action === 'control_users'
          ? urlTaskCreateControlUser()
          : urlTaskCreateObserverUser()
      const validation =
        action === 'control_users'
          ? obj.userId && obj.taskId
          : obj.entityId && obj.entityTypeId && obj.taskId
      if (validation) {
        return axios.post(url, obj).then(res => {
          if (action === 'control_users') {
            selectedUser.value = null
          } else {
            selectedObServerUser.value = null
          }

          task[action].push(res.data)
          return res
        })
      }
    }

    const handleRemoveUser = (
      action: 'control_users' | 'observers',
      userId: number
    ) => {
      const url =
        action === 'control_users'
          ? urlTaskDeleteControlUser
          : urlTaskDeleteObserverUser
      return axios.delete(url(userId)).then(() => {
        task[action] = task[action].filter(u => u.id !== userId)
      })
    }

    const handleAccessAddUser = (action: 'control_users' | 'observers') => {
      const selectUser =
        action === 'control_users'
          ? selectedUser.value
          : selectedObServerUser.value
      if (!selectUser) {
        setSnackbar({
          text: `Спочатку оберіть ${
            action === 'control_users' ? 'контролюючого' : 'наглядача'
          }`,
          color: 'error',
        })
      } else {
        handleCreateTaskUser(action, selectUser)
      }
    }

    Promise.all([
      'getTask' in useTask ? useTask.getTask() : Promise.resolve(),
    ]).then(() => {
      socket.on(taskNotification, res => {
        if (res.id === task.id) {
          task.$set(res)
        }
      })
      initLoading.value = false
    })

    const updateExecutor = () => {
      if (!createRoute.value) {
        return axios.post(urlTaskUpdateExecutor(task.id), {
          executorId: task.executorId,
        })
      }
    }

    const updateDeadline = async (): Promise<void> => {
      return handleAsync('updateDeadline', async () => {
        if (
          !createRoute.value &&
          task.deadline &&
          task.deadline.length === 17
        ) {
          return axios.post(urlTaskUpdateDeadline(task.id), {
            deadline: task.deadline,
          })
        }
      })
    }

    return {
      createRoute,
      initLoading,
      actions,
      selectedUser,
      selectedObServerUser,
      handleAccessAddUser,
      handleRemoveUser,
      updateExecutor,
      updateDeadline,
      task,
      ...omit(useTask, 'task'),
    }
  },
}
</script>

<style lang="scss" scoped>
.buttons > * {
  display: block;
  margin-bottom: 1rem;
}
</style>
