<template>
  <div>
    <div>
      <div>
        <comment-item
          v-for="comment in displayedComments"
          :key="comment.id"
          :comment="comment"
          :loading="loadingState.delete"
          class="comment-transition mb-3"
          @delete="deleteComment"></comment-item>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          v-if="displayedComments.length < localComments.length"
          class="mb-3"
          size="small"
          @click="showAllComments"
          >Показати всі
        </v-btn>
      </div>
      <BaseTextarea
        v-model="newComment"
        class="mb-3"
        placeholder="Новий коментар"
        rows="2" />
      <v-btn
        :disabled="!newComment"
        :loading="loadingState.add"
        class="w-100"
        @click="addComment">
        Зберегти
      </v-btn>
    </div>
  </div>
</template>

<script>
import CommentItem from './CommentItem.vue'
import { computed, reactive, ref, watch } from 'vue'
import { axios } from '@/plugins/index.js'
import { urlCommentCreate, urlCommentDelete } from '@/utils/urls.js'
import { useStore } from 'vuex'

export default {
  components: { CommentItem },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore()
    const user = computed(() => store.state.user.userData)

    const newComment = ref(null)
    const localComments = ref([])
    localComments.value.push(...(props.dialog.dialogData.comments || []))
    const loadingState = reactive({
      add: false,
      delete: false,
    })
    const displayedComments = ref([...localComments.value.slice(0, 2)])

    watch(
      () => localComments.value,
      () => {
        // When localComments change, update displayedComments with a transition
        displayedComments.value = [...localComments.value]
      }
    )

    const addComment = () => {
      loadingState.add = true

      return axios
        .post(urlCommentCreate(), {
          comment: newComment.value,
          entityId: props.dialog.dialogData.entityId,
          entityTypeId: props.dialog.dialogData.entityTypeId,
        })
        .then(res => {
          localComments.value.push(res.data)
          props.dialog.dialogData.updateComments(localComments.value)

          newComment.value = null
        })
        .finally(() => {
          loadingState.add = false
        })
    }

    const deleteComment = async commentId => {
      loadingState.delete = commentId

      return axios
        .delete(urlCommentDelete(commentId))
        .then(() => {
          localComments.value = localComments.value.filter(
            c => c.id !== commentId
          )
          props.dialog.dialogData.updateComments(localComments.value)
        })
        .finally(() => {
          loadingState.delete = false
        })
    }

    const showAllComments = () => {
      displayedComments.value = [...localComments.value]
    }

    return {
      loadingState,
      localComments,
      newComment,
      addComment,
      deleteComment,
      showAllComments,
      displayedComments,
      user,
    }
  },
}
</script>

<style lang="scss" scoped>
.comment-transition {
  transition: opacity 0.5s ease, max-height 0.5s ease;
  max-height: 1000px; // Set a value larger than the expected height, adjust as needed
}

.comment-transition.hidden {
  opacity: 0;
  max-height: 0;
}
</style>
