import { computed } from 'vue'
import {
  axios,
  minLength,
  required,
  useBreadcrumb,
  useRoute,
  useStore,
  useVuelidate,
} from '@/plugins/index.js'
import { urlTaskSingle, urlTaskUpdate } from '@/utils/urls.js'
import { cachedObject } from 'best-modules/utils'
import { setSnackbar } from 'best-modules/plugins'
import { Task } from '@/utils/types/task'

export function useEditTask() {
  const store = useStore()
  const route = useRoute()
  const breadcrumb = useBreadcrumb()
  const task = cachedObject<Task>({} as Task)
  task.$setIgnore(
    'comments',
    'statusId',
    'status',
    'control_users',
    'observers'
  )
  const taskType = computed(() => {
    return {
      execution: task.typeId === 1,
      familiarization: task.typeId === 2,
      errand: task.typeId === 3,
    }
  })

  const rules = computed(() => {
    return {
      authorId: !taskType.value.execution ? { required } : {},
      topic: taskType.value.execution ? { required } : {},
      description: taskType.value.errand ? {} : { required },
      executorId: !taskType.value.familiarization ? { required } : {},
      accessEntities: taskType.value.familiarization
        ? { required, minLength: minLength(0) }
        : {},
      deadline: { required },
      attachment: !taskType.value.execution ? { required } : {},
    }
  })
  const v$ = useVuelidate(rules, task)

  const userId = computed(() => store.state.user.userData?.id)
  const isAuthor = computed(() => userId.value === task.authorId)

  const disabled = computed(() => {
    return [3, 4].includes(task.statusId) || task.isOverdue
  })

  const getTask = () => {
    return axios
      .get(urlTaskSingle(route.params.id))
      .then(res => {
        task.$set(res.data)
        return res.data
      })
      .then(res => {
        const taskDirection =
          res.executorId === userId.value ? 'incoming' : 'outgoing'
        breadcrumb.set([
          {
            title: `${
              taskDirection === 'outgoing' ? 'Вихідні' : 'Вхідні'
            } завдання`,
            to: { name: `${taskDirection}-tasks` },
            index: 0,
          },
          { title: res.topic, index: 1 },
        ])
      })
  }

  const submit = () => {
    v$.value.$validate()
    if (!v$.value.$error) {
      return axios.post(urlTaskUpdate(task.id), task.$object).then(res => {
        task.$set(res.data)

        const entityName = taskType.value.errand ? 'Доручення' : 'Завдання'
        setSnackbar({ text: `${entityName} оновлено` })
        return res.data
      })
    }
  }

  const cancel = () => {
    return task.$reset()
  }

  return {
    v$,
    task,
    taskType,
    submit,
    cancel,
    getTask,
    disabled,
    isAuthor,
  }
}
