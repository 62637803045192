<template>
  <v-card
    v-for="(flaw, idx) in flaws"
    :key="flaw.id"
    :loading="$loading.isLoading('deleteFlaw')"
    class="shadow-1 cursor-default"
    rounded="lg"
    variant="flat">
    <v-card-text :class="flaw.expanded ? 'pb-3' : null" class="py-2">
      <div>
        <div
          :class="flaw.expanded ? 'pb-1' : null"
          class="header"
          @click="toggleExpand(idx)">
          <h2>{{ flaw.text }}</h2>
          <div class="d-flex align-center gap-4">
            <v-btn-group density="compact" rounded="lg" variant="outlined">
              <v-btn
                v-if="
                  flaw.activeOption === 'recommendation' && audit.statusId === 2
                "
                color="primary"
                density="comfortable"
                rounded="lg"
                size="x-small"
                @click.stop="createAuditRecommendation(flaw.id)">
                <v-icon class="mr-1" color="primary">mdi-plus-circle</v-icon>
                Рекомендація
              </v-btn>
              <v-btn
                v-if="flaw.activeOption === 'fact' && audit.statusId === 2"
                color="primary"
                density="comfortable"
                rounded="lg"
                size="x-small"
                @click.stop="createRisk(flaw.id)">
                <v-icon class="mr-1" color="primary">mdi-plus-circle</v-icon>
                Ризик
              </v-btn>
            </v-btn-group>
            <v-btn-group
              density="compact"
              divided
              rounded="lg"
              variant="outlined">
              <v-btn
                v-if="audit.statusId === 2"
                size="x-small"
                @click.stop="updateFlaw(flaw)">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="audit.statusId === 2"
                size="x-small"
                @click.stop="deleteFlawHandler(flaw)">
                <v-icon color="red"> mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn size="x-small">
                <v-icon
                  :icon="flaw.expanded ? collapseIcon : expandIcon"></v-icon>
              </v-btn>
            </v-btn-group>
          </div>
        </div>
        <transition name="expand">
          <div v-if="flaw.expanded" class="content">
            <v-btn-group
              class="custom-btn-group mb-3"
              density="compact"
              rounded="lg"
              variant="flat">
              <v-btn
                v-for="option in options"
                :key="option.value"
                :class="{ 'active-btn': flaw.activeOption === option.value }"
                size="x-small"
                style="letter-spacing: 0.5px"
                @click="setActive(option.value, idx)">
                {{ option.label }}
              </v-btn>
            </v-btn-group>

            <div
              v-if="flaw.activeOption === 'fact'"
              class="d-flex flex-column gap-4">
              <audit-risk-list
                v-if="flaw.risks.length"
                :audit="audit"
                :flaw="flaw"
                @remove-risk="onRemoveRisk"></audit-risk-list>
              <div class="d-flex flex-column">
                <span class="label">Опис</span>
                {{ flaw?.factorFlaw }}
              </div>
            </div>
            <div v-if="flaw.activeOption === 'recommendation'">
              <audit-recommendation-list
                :flaw-id="flaw.id"></audit-recommendation-list>
            </div>
          </div>
        </transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AuditRecommendationList from '@/pages/audit/single/AuditRecommendationList.vue'
import { onMounted, ref, watch } from 'vue'
import { openDialog } from '@/dialog'
import AuditRiskList from '@/pages/audit/single/AuditRiskList.vue'
import axios from '@/plugins/axios'
import { urlAuditFlawDelete } from '@/utils/urls'
import { handleAsync, setSnackbar } from 'best-modules/plugins'

export default {
  name: 'AuditExpansionPanel',
  emits: ['delete-flaw'],
  components: { AuditRiskList, AuditRecommendationList },
  props: {
    flaws: { type: Array, required: true },
    audit: { type: Object },
  },
  setup(props, { emit }) {
    const flaws = ref([...props.flaws])

    const collapseIcon = 'mdi-chevron-up'
    const expandIcon = 'mdi-chevron-down'
    const options = ref([
      { label: 'Факт недоліку', value: 'fact' },
      { label: 'Рекомендація', value: 'recommendation' },
    ])

    const setActive = (value, index) => {
      flaws.value[index].activeOption = value
    }

    const toggleExpand = index => {
      flaws.value[index].expanded = !flaws.value[index].expanded
    }

    watch(
      () => props.flaws,
      newFlaws => {
        flaws.value = newFlaws
      },
      { deep: true, immediate: true }
    )

    onMounted(() => {
      flaws.value.forEach(flaw => {
        flaw.expanded = false
        flaw.activeOption = 'fact'
      })
      if (flaws.value.length) {
        flaws.value[0].expanded = true
      }
    })

    const updateFlaw = flaw => {
      openDialog({
        name: 'AuditFlaw',
        action: 'update',
        dialogData: {
          flaw,
        },
        params: {
          cb: updatedFlaw => {
            const flawIndex = flaws.value.findIndex(
              f => f.id === updatedFlaw.id
            )
            if (flawIndex !== -1) {
              flaws.value[flawIndex] = {
                ...updatedFlaw,
                recommendations: updatedFlaw.hasOwnProperty('recommendations')
                  ? updatedFlaw.recommendations
                  : flaw.recommendations,
                expanded: flaw?.expanded,
                activeOption: flaw?.activeOption,
              }
            }
          },
        },
      })
    }

    const deleteFlawHandler = flaw => {
      return handleAsync('deleteFlaw', () => {
        return axios.delete(urlAuditFlawDelete(flaw.id)).then(() => {
          flaws.value = flaws.value.filter(itm => itm.id !== flaw.id)
          emit('delete-flaw', flaw.id)
          setSnackbar({ text: 'Факт недоліку видалено' })
        })
      })
    }

    const onRemoveRisk = ({ riskId, localFlaw }) => {
      const findFlawItem = flaws.value.find(itm => itm.id === localFlaw?.id)

      if (findFlawItem?.risks) {
        findFlawItem.risks = findFlawItem.risks.filter(
          item => item.id !== riskId
        )
      }
    }

    const createAuditRecommendation = flawId => {
      const flaw = flaws.value.find(f => f.id === flawId)
      if (!flaw) {
        return
      }
      openDialog({
        name: 'AuditRecommendation',
        dialogData: {
          auditFlawId: flawId,
        },
        params: {
          cb: recommendation => {
            flaw.recommendations.push(recommendation)
          },
        },
      })
    }

    const createRisk = flawId => {
      const flaw = flaws.value.find(f => f.id === flawId)
      if (!flaw) {
        return
      }

      openDialog({
        name: 'AuditRisk',
        dialogData: {
          flawId: flawId,
        },
        params: {
          cb: risk => {
            flaw.risks.push(risk)
          },
        },
      })
    }

    return {
      createAuditRecommendation,
      toggleExpand,
      updateFlaw,
      collapseIcon,
      expandIcon,
      options,
      setActive,
      createRisk,
      deleteFlawHandler,
      onRemoveRisk,
    }
  },
}
</script>

<style lang="scss" scoped>
.active-btn {
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.custom-btn-group {
  padding: 4px;
  gap: 8px;
  letter-spacing: 0.5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s ease;
}

.expand-enter-from,
.expand-leave-to {
  opacity: 0;
  transform: scaleY(0);
  height: 0;
}

.content {
  overflow: hidden;
}
</style>
