<template>
  <v-card>
    <v-card-text>
      <b-server-table
        :key="renderKey"
        :headers="headers"
        :request-func="getTaskList"
        show-filters
        show-search
        @click:row="openRow">
        <template #filters>
          <b-server-filter-item
            v-slot="{ props }"
            directory="taskStatuses"
            query-key="statusId">
            <b-select
              clearable
              label="Статус"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            directory="taskTypes"
            query-key="typeId">
            <b-select
              clearable
              label="Тип"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            directory="userList"
            query-key="executors[]">
            <b-autocomplete
              chips
              clearable
              label="Виконавець"
              multiple
              placeholder="Оберіть зі списку"
              v-bind="props"></b-autocomplete>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            :query-key="['startDate', 'endDate']">
            <b-input-date label="Дата виконання" range v-bind="props" />
          </b-server-filter-item>
        </template>
        <template #action-button>
          <div class="d-flex justify-end mb-3 gap-3">
            <printed-form></printed-form>
            <v-btn rounded="lg" @click.stop="createTask">
              <v-icon class="mr-1">mdi-plus-circle</v-icon>
              Створити завдання
            </v-btn>
          </div>
        </template>
        <template #item.executor="{ item }">
          <span>{{
            getUserName(item.executor, { initials: true }) || '---'
          }}</span>
        </template>
        <template #item.author="{ item }">
          <span>{{
            getUserName(item.author, { initials: true }) || '---'
          }}</span>
        </template>
        <template #item.completedDate="{ item }">
          <div class="d-flex align-center gap-3">
            <span>{{ item.completedDate || '---' }}</span>
            <v-menu v-if="item.completedDate" open-on-hover>
              <template #activator="{ props }">
                <v-btn
                  density="compact"
                  icon="mdi-information-variant"
                  size="small"
                  v-bind="props"
                  variant="plain"></v-btn>
              </template>

              <v-card>
                <v-card-text>
                  {{ item.completionReport || item.rejectComment }}
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </template>
        <template #item.connect="{ item }">
          <span
            v-if="item.entityTypeId && item.entityId"
            class="link"
            @click.stop="
              $router.push({
                name:
                  item.entityTypeId === 3
                    ? 'single-order'
                    : item.entityTypeId === 9
                    ? 'audit-single'
                    : 'correspondence-single',
                params: { id: item.entityId },
              })
            ">
            <span v-if="item.entityTypeId === 3">Наказ</span>
            <span v-else-if="item.entityTypeId === 9">Аудит</span>
            <span v-else>Кореспонденція</span>
          </span>
          <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
        </template>
        <template #[`item.contractor`]="{ item }">
          <v-tooltip
            v-if="item.contractor"
            :text="item.contractor?.name"
            bottom>
            <template #activator="{ props }">
              <span
                class="link"
                style="
                  display: block;
                  width: 230px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                v-bind="props"
                @click.stop="
                  $router.push({
                    name: 'single-contractor',
                    params: { id: item?.contractor?.id },
                  })
                "
                >{{ item.contractor?.name }}</span
              >
            </template>
          </v-tooltip>
          <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
        </template>
        <template #item.actions="{ item }">
          <TableActions
            :actions="[
              {
                label: 'Взяти в роботу',
                action: () => {
                  actions.taskTakeToWork(item, item.id).then(setTask)
                },
                icon: 'mdi-play',
                disabled: actions.disabled(item).takeToWork.value,
              },
              {
                label: 'Завершити задачу',
                action: () => actions.taskComplete(item, item.id, setTask),
                icon: 'mdi-bell-check-outline',
                disabled: actions.disabled(item).complete.value,
              },
              {
                label: 'Копіювати задачу',
                action: () => actions.copyTask(item),
                icon: 'mdi-content-copy',
                disabled: actions.disabled(item).copy.value,
              },
              {
                label: 'Делегувати',
                action: () => actions.taskDelegate(item, item.id, setTask),
                icon: 'mdi-link',
                disabled: actions.disabled(item).delegate.value,
              },
              {
                label: 'Відхилити задачу',
                action: () => actions.taskReject(item, item.id, setTask),
                icon: 'mdi-clock-out',
                disabled: actions.disabled(item).reject.value,
              },
              {
                label: 'Видалити задачу',
                action: () => actions.taskDelete(item, item.id, deleteTask),
                icon: 'mdi-delete',
                disabled: actions.disabled(item).del.value,
              },
            ]" />
        </template>
        <template #bottom></template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { axios, useBreadcrumb, useRoute } from '@/plugins'
import { computed, ref } from 'vue'
import { urlIncomingTasks, urlOutgoingTasks } from '@/utils/urls.js'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { useTaskActions } from '@/pages/tasks/taskActions.js'
import TableActions from '@/components/TableActions.vue'
import { headers } from './headers.ts'
import PrintedForm from './PrintedForm.vue'
import {
  BInputDate,
  BServerFilterItem,
  BServerTable,
} from 'best-modules/components/index'

export default {
  components: {
    TableActions,
    PrintedForm,
    BServerTable,
    BServerFilterItem,
    BInputDate,
  },
  methods: { getUserName },
  setup() {
    const route = useRoute()
    const renderKey = ref(1)
    const breadcrumb = useBreadcrumb()
    breadcrumb.set([], { type: 'replace' })

    const url = computed(() => {
      switch (route.name) {
        case 'incoming-tasks':
          return urlIncomingTasks
        case 'outgoing-tasks':
          return urlOutgoingTasks
        default:
          return urlIncomingTasks
      }
    })

    const openRow = event => {
      navigateToItem('single-task', event.id)
    }

    const getTaskList = query => {
      return axios.get(url.value(query)).then(res => res.data)
    }

    const actions = useTaskActions()
    const setTask = task => {
      taskList.value = taskList.value.map(t => (t.id === task.id ? task : t))
    }
    const pushTask = task => taskList.value.push(task)
    const deleteTask = taskId => {
      taskList.value = taskList.value.filter(t => t.id !== taskId)
    }

    const createTask = () => {
      navigateToItem('create-task', { typeId: 1 })
    }

    return {
      headers,
      navigateToItem,
      getTaskList,
      renderKey,
      openRow,
      actions,
      setTask,
      pushTask,
      deleteTask,
      createTask,
    }
  },
}
</script>

<style lang="scss"></style>
