<template>
  <div>
    <div v-if="localFlaw.risks.length" class="d-flex gap-10 align-center">
      <div class="d-flex flex-column">
        <span class="label">Вид ризику</span>
        <div class="d-flex gap-2 flex-column">
          <div v-for="risk in localFlaw.risks || []" :key="risk.id">
            <span>{{ risk?.risk_type?.name }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <span class="label">Рівень ризику</span>
        <div class="d-flex gap-2 flex-column">
          <div v-for="risk in localFlaw.risks || []" :key="risk.id">
            <span>{{ risk?.risk_level?.name }}</span>
          </div>
        </div>
      </div>
      <div v-if="audit.statusId === 2" class="d-flex flex-column align-center">
        <span class="label">‎</span>
        <div class="d-flex gap-2 flex-column">
          <div v-for="risk in localFlaw.risks || []" :key="risk.id">
            <v-tooltip text="Видалити ризик">
              <template #activator="{ props }">
                <v-btn
                  :loading="$loading.isLoading(`removeRisk_${risk.id}`)"
                  size="x-small"
                  v-bind="props"
                  variant="text"
                  @click="removeRisk(risk.id)">
                  <v-icon color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { auditRiskListHeaders } from '../headers'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import axios from '@/plugins/axios'
import { urlAuditRiskDelete } from '@/utils/urls'
import { reactive } from 'vue'

export default {
  name: 'AuditRiskList',
  emits: ['remove-risk'],
  props: {
    flaw: { type: Object, required: true },
    audit: { type: Object, required: true },
  },
  data: () => {
    return {
      auditRiskListHeaders,
    }
  },
  setup(props, { emit }) {
    const localFlaw = reactive({ ...props.flaw })

    const removeRisk = (riskId: number) => {
      return handleAsync(`removeRisk_${riskId}`, () => {
        return axios.delete(urlAuditRiskDelete(riskId)).then(() => {
          emit('remove-risk', { riskId, localFlaw })
          setSnackbar({ text: 'Ризки оновлено' })
          if (localFlaw.risks) {
            localFlaw.risks = localFlaw.risks.filter(r => r.id !== riskId)
          }
        })
      })
    }

    return { removeRisk, localFlaw }
  },
}
</script>

<style lang="scss" scoped></style>
