<template>
  <div class="w-100">
    <div class="cursor-default">
      <div class="d-flex align-center justify-space-between">
        <span
          v-for="(row, idx) in comment.comment.split('\n')"
          :key="idx"
          style="font-size: 13px">
          {{ row }} <br />
        </span>
        <b-action-menu
          v-if="!hideDelete"
          :actions="[
            {
              title: 'Видалити',
              action: () => $emit('delete', comment.id),
              icon: 'mdi-delete',
              disabled: !isAuthor,
            },
          ]"
          icon="mdi-dots-horizontal"></b-action-menu>
      </div>
      <div
        class="d-flex align-center justify-space-between mt-3"
        style="color: #8e92bc; font-size: 12px">
        <div class="d-flex align-center w-100">
          <div v-if="!hidePhoto" class="mr-1">
            <BaseImage
              v-if="comment.user.photo"
              :src="comment.user.photo"
              rounded
              style="cursor: pointer"
              width="32"></BaseImage>
            <v-avatar v-else class="cursor-pointer" color="primary">
              <v-icon icon="mdi-account-circle"></v-icon>
            </v-avatar>
          </div>
          <span>{{ getUserName(comment.user, { initials: true }) }}</span>
        </div>
        <span class="d-block w-50 text-end">
          {{ formatDate(comment.created_at, 'response') }}
        </span>
      </div>
      <div class="d-flex justify-end mt-2">
        <slot name="showAllComments"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/plugins/index.js'
import { computed, inject } from 'vue'
import { getUserName } from '@/utils/helpers.js'
import { formatDate } from '@/plugins/axios/helpers.js'
import { BActionMenu } from 'best-modules/components/index'

export default {
  components: {
    BActionMenu,
  },
  props: {
    comment: { type: Object, required: true },
    loading: { type: [Number, Boolean] },
    hideDelete: { type: Boolean },
    hidePhoto: { type: Boolean },
  },
  emits: ['delete'],
  methods: { formatDate, getUserName },
  setup(props) {
    const store = useStore()
    const loadingState = inject('loadingState', {})

    const userId = computed(() => store.state.user.userData?.id)
    const isAuthor = computed(() => {
      return props.comment.userId === userId.value
    })

    return { loadingState, isAuthor }
  },
}
</script>

<style lang="scss"></style>
