<template>
  <span class="label">{{ label }}</span>
  <component
    :is="component === 'VSelect' ? VSelect : VAutocomplete"
    v-model="value"
    chips
    closable-chips
    item-title="name"
    item-value="id"
    multiple
    placeholder="Оберіть зі списку"
    v-bind="attrs">
    <template #chip="{ item, props }">
      <v-card>
        <v-card-text class="pa-1 pl-2 d-flex align-center" v-bind="props">
          <span>{{ item.title }}</span>
          <v-btn
            density="compact"
            icon
            size="small"
            variant="text"
            @click="removeItem(item)">
            <v-icon color="black" size="x-small">mdi-close</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </component>
</template>

<script>
import { computed } from 'vue'
import { VAutocomplete, VSelect } from 'vuetify/components'

export default {
  name: 'BaseMultiplySelect',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    component: {
      type: String,
      required: true,
      default: VSelect,
    },
  },
  computed: {
    VAutocomplete() {
      return VAutocomplete
    },
    VSelect() {
      return VSelect
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit, attrs }) {
    const value = computed({
      get: () => props.modelValue,
      set: v => {
        emit('update:model-value', v)
      },
    })

    const removeItem = item => {
      const newValue = value.value.filter(v => v !== item.raw.id)
      emit('update:model-value', newValue)
    }

    return {
      value,
      attrs,
      removeItem,
    }
  },
}
</script>
