<template>
  <v-card elevation="1">
    <v-card-text>
      <Skeleton v-if="$loading.isLoading('getContractorList')" />
      <div v-else>
        <div class="d-flex justify-end">
          <v-btn class="mb-2" rounded="lg" @click="createContractor">
            <v-icon class="mr-1">mdi-plus-circle</v-icon>
            Створити контрагента
          </v-btn>
        </div>
        <v-data-table
          :disable-pagination="true"
          :headers="headers"
          :hide-default-footer="true"
          :hide-default-header="!contractorList.length"
          :items="contractorList"
          :items-per-page="-1"
          dense
          @dblclick:row="openRow">
          <template #bottom></template>
        </v-data-table>
      </div>
      <Pagination :getItems="getContractorList" :trigger="pgTrigger" />
    </v-card-text>
  </v-card>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import Pagination from '@/components/Pagination.vue'
import { axios } from '@/plugins'
import { ref } from 'vue'
import { navigateToItem } from '@/utils/helpers.js'
import { urlContractorList } from '@/utils/urls.js'
import { handleAsync } from 'best-modules/plugins'
import { openDialog } from '@/dialog/index.ts'
import { pushDirectoryItem } from '@/directory/index.ts'

export default {
  components: { Pagination, Skeleton },
  data: () => ({
    headers: [
      {
        key: 'type.name',
        title: 'Тип',
        align: 'start',
      },
      { key: 'shortName', title: 'Ім`я', align: 'start' },
      { key: 'code', title: 'ЄДРПОУ/ІНН', align: 'start' },
    ],
  }),
  setup() {
    const contractorList = ref([])
    const pgTrigger = ref(1)

    const getContractorList = page => {
      return handleAsync('getContractorList', () => {
        return axios
          .get(urlContractorList({ page, paginate: true }))
          .then(res => {
            contractorList.value = res.data.data

            return res.data
          })
      })
    }

    const openRow = (e, row) => {
      navigateToItem('single-contractor', row.item.id)
    }

    const createContractor = () => {
      openDialog({
        name: 'Contractor',
        params: {
          cb: contractor => {
            pushDirectoryItem('contractorList', contractor)
            navigateToItem('single-contractor', contractor.id)
          },
        },
      })
    }

    return {
      pgTrigger,
      openRow,
      contractorList,
      getContractorList,
      navigateToItem,
      createContractor,
    }
  },
}
</script>

<style lang="scss" scoped></style>
