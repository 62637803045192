<template>
  <v-card elevation="1">
    <v-card-text>
      <b-server-table
        :key="tableRenderKey"
        :headers="auditListHeaders"
        :request-func="getAuditList"
        show-filters
        show-search
        @click:row="
          $router.push({
            name: 'audit-single',
            params: { id: $event.id },
          })
        ">
        <template #item.actions="{ item }">
          <b-action-menu
            :actions="[
              {
                title: 'Переглянути',
                to: { name: 'audit-single', params: { id: item.id } },
                icon: 'mdi-eye',
              },
              {
                title: 'Редагувати',
                action: () => updateAudit(item),
                icon: 'mdi-pencil',
                disabled: item.statusId === 3,
              },
            ]"
            icon="mdi-dots-horizontal"></b-action-menu>
        </template>
        <template #action-button>
          <v-btn color="primary" rounded="lg" @click.stop="createAudit">
            <v-icon class="mr-1">mdi-plus-circle</v-icon>
            Створити аудит
          </v-btn>
        </template>
        <template #filters>
          <b-server-filter-item
            v-slot="{ props }"
            directory="auditTypes"
            query-key="typeId">
            <b-select clearable label="Тип" v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            directory="auditStatuses"
            query-key="statusId">
            <b-select clearable label="Статус" v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            query-key="startScheduledCheckDate">
            <b-input-date
              label="Планова дата початку"
              modelValueFormat="iso"
              v-bind="props"></b-input-date>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            query-key="endScheduledCheckDate">
            <b-input-date
              label="Планова дата закінчення"
              modelValueFormat="iso"
              v-bind="props"></b-input-date>
          </b-server-filter-item>
          <b-server-filter-item v-slot="{ props }" query-key="year">
            <b-input
              label="Рік"
              mask="20##"
              placeholder="yyyy"
              v-bind="props"></b-input>
          </b-server-filter-item>
        </template>
        <template #item.status="{ item }">
          <v-chip
            :color="
              item.status.id === 2
                ? 'yellow-lighten-3'
                : item.status.id === 3
                ? 'green'
                : 'grey'
            "
            size="small"
            variant="flat">
            <template #default>
              <span :style="{ color: item.status.id === 2 ? '#CA8A04' : '' }">{{
                item?.status?.name
              }}</span>
            </template>
          </v-chip>
        </template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import {
  BActionMenu,
  BInputDate,
  BServerFilterItem,
  BServerTable,
} from 'best-modules/components'
import { auditListHeaders } from './headers'
import axios from '@/plugins/axios/index.js'
import { urlAuditList } from '@/utils/urls'
import { openDialog } from '@/dialog'
import { useRouter } from 'vue-router'
import { Audit } from '@/utils/types/audit'
import { PaginationList } from 'best-modules/components/server-table/types'
import { CustomDate } from 'best-modules/utils'
import { ref } from 'vue'

export default {
  name: 'AuditList',
  components: { BServerTable, BServerFilterItem, BActionMenu, BInputDate },
  data: () => {
    return {
      auditListHeaders,
      CustomDate,
    }
  },
  setup() {
    const router = useRouter()
    const tableRenderKey = ref<number>(1)
    const getAuditList = (query): Promise<PaginationList<Audit>> => {
      return axios.get(urlAuditList(query)).then(res => res.data)
    }

    const createAudit = () => {
      openDialog({
        name: 'Audit',
        action: 'create',
        params: {
          cb: audit => {
            router.push({ name: 'audit-single', params: { id: audit.id } })
          },
        },
      })
    }
    const updateAudit = (audit: Audit) => {
      openDialog({
        name: 'Audit',
        action: 'update',
        dialogData: {
          audit,
        },
        params: {
          cb: () => (tableRenderKey.value += 1),
        },
      })
    }

    return { getAuditList, createAudit, updateAudit, tableRenderKey }
  },
}
</script>

<style lang="scss" scoped></style>
