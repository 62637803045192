<template>
  <div>
    <div v-if="isPreviewMode" class="d-flex gap-3 align-center mb-3">
      <v-btn
        density="compact"
        icon="mdi-content-copy"
        variant="plain"
        @click="copyText(link)"></v-btn>
      <div class="text-truncate">
        <a :href="link" class="link" target="_blank">
          {{ link }}
        </a>
      </div>
    </div>
    <v-radio-group
      v-model="clientAccess.isSignerDocument"
      :readonly="isPreviewMode || !canSetSignDocument"
      inline>
      <v-radio
        v-if="canSetSignDocument"
        :value="true"
        label="Підписання"></v-radio>
      <v-radio :value="false" label="Перегляд"></v-radio>
    </v-radio-group>
    <v-row>
      <v-col cols="6">
        <b-select
          v-model="clientAccess.linkTypeId"
          :error-messages="getErrorMessages(v$.linkTypeId)"
          :items="
            $directory.get('clientAccessLinkTypes', clientAccess.link_type)
          "
          :loading="$loading.isLoading('clientAccessLinkTypes')"
          label="Тип доступу"
          @blur="v$.linkTypeId.$touch()"></b-select>
      </v-col>
      <v-col cols="6">
        <base-date-picker
          v-model="clientAccess.endDate"
          :error-messages="getErrorMessages(v$.endDate)"
          :min-date="new Date()"
          :readonly="isPreviewMode"
          label="Срок дії доступу"
          time-picker
          @blur="v$.endDate.$touch()"></base-date-picker>
      </v-col>
    </v-row>

    <b-select
      v-if="!isPreviewMode"
      v-model="clientAccess.contractor"
      :error-messages="getErrorMessages(v$.contractorId)"
      :item-title="c => `${c.name} (${c.code})`"
      :items="dialog.dialogData.document.contractors.map(c => c.contractor)"
      label="Контрагент"
      placeholder="Оберіть контрагента"
      return-object
      @blur="v$.contractorId.$touch()"
      @update:model-value="
        clientAccess.contractorId = $event?.id || null
      "></b-select>
    <b-input
      v-else
      :model-value="`${clientAccess.contractor?.name} (${clientAccess.contractor?.code})`"
      label="Контрагент"
      readonly>
    </b-input>

    <v-scroll-x-transition hide-on-leave>
      <div v-show="clientAccess.contractor">
        <v-scroll-x-transition hide-on-leave>
          <div v-show="clientAccess.linkTypeId === 3">
            <b-select
              v-if="!isPreviewMode"
              v-model="clientAccess.phoneId"
              :error-messages="getErrorMessages(v$.phoneId)"
              :item-title="p => `${p.phone} (${p.type.name})`"
              :items="clientAccess.contractor?.phones || []"
              label="Телефон"
              @blur="v$.phoneId.$touch()"></b-select>
            <b-input
              v-else
              :model-value="`${clientAccess.phone?.phone} (${clientAccess.phone?.type?.name})`"
              label="Телефон"
              readonly>
            </b-input>
          </div>
        </v-scroll-x-transition>
        <v-checkbox
          v-model="clientAccess.isSendEmail"
          :readonly="isPreviewMode || [2, 3].includes(clientAccess.linkTypeId)"
          label="Відправити посилання на пошту"></v-checkbox>
        <v-scroll-x-transition hide-on-leave>
          <div v-show="clientAccess.isSendEmail">
            <b-select
              v-if="!isPreviewMode"
              v-model="clientAccess.emailId"
              :error-messages="getErrorMessages(v$.emailId)"
              :item-title="e => `${e.email} (${e.type.name})`"
              :items="clientAccess.contractor?.emails || []"
              label="Пошта"
              @blur="v$.emailId.$touch()"></b-select>
            <b-input
              v-else
              :model-value="`${clientAccess.email?.email} (${clientAccess.email?.type?.name})`"
              label="Пошта"
              readonly></b-input>
          </div>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <base-textarea
            v-show="clientAccess.isSendEmail"
            v-model="clientAccess.text"
            :error-messages="getErrorMessages(v$.text)"
            label="Коментар до листа-запрошення"
            @blur="v$.text.$touch()"></base-textarea>
        </v-scroll-x-transition>
      </div>
    </v-scroll-x-transition>

    <div v-if="isPreviewMode">
      <div v-if="isTermOver" class="text-error font-weight-bold">
        Термін дії доступу закінчився
      </div>
      <v-btn
        v-else
        :loading="$loading.isLoading('deleteAccess')"
        color="error"
        variant="outlined"
        @click="deleteAccess">
        Закрити доступ
      </v-btn>
    </div>
    <b-action-buttons v-else :confirm="createAccess" :reject="dialog.close">
    </b-action-buttons>
  </div>
</template>

<script lang="ts">
import { urlClientAccessCreate, urlClientAccessDelete } from '@/utils/urls'
import { computed, PropType, provide, reactive, ref, watch } from 'vue'
import { Dialog } from '@/dialog'
import { axios, required, requiredIf, useVuelidate } from '@/plugins'
import {
  copyText,
  CustomDate,
  getErrorMessages,
  LocaleDate,
} from 'best-modules/utils'
import { fillDirectory } from '@/directory'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { handleAsync } from 'best-modules/plugins'
import { cloneDeep } from 'lodash'
import { BActionButtons } from 'best-modules/components'
import { DefaultItem } from 'best-modules/plugins/directory/types'

type ClientAccess = {
  id: number
  documentId: number
  contractorId: number
  contractor?: {
    id: number
    name: string
    code: string
    phones: object[]
    emails: object[]
  }
  linkTypeId: number
  link_type: {
    id: number
    name: string
  }
  endDate: LocaleDate
  phoneId: number
  emailId: number
  phone?: {
    phone: string
    id: number
    type: DefaultItem
  }
  email?: {
    email: string
    id: number
    type: DefaultItem
  }
  isSignerDocument: boolean
  isSendEmail: boolean
  text: string
  routeToken?: string
  token?: {
    token: string
  }
}

export default {
  name: 'ClientAccess',
  components: { BaseDatePicker, BActionButtons },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getErrorMessages, copyText },
  setup(props) {
    const action = ref<'create' | 'preview'>(
      props.dialog.action as 'create' | 'preview'
    )
    if (action.value === 'create') {
      fillDirectory('clientAccessLinkTypes')
    }

    const isPreviewMode = computed(() => action.value === 'preview')
    provide('readonly', isPreviewMode)
    const clientAccess = reactive<Partial<ClientAccess>>({
      documentId: null,
      contractorId: null,
      linkTypeId: null,
      endDate: null,
      phoneId: null,
      emailId: null,
      isSignerDocument: false,
      isSendEmail: false,
      text: null,
    })
    Object.assign(clientAccess, cloneDeep(props.dialog.dialogData.access))

    const rules = {
      contractorId: { required },
      linkTypeId: { required },
      endDate: { required },
      phoneId: {
        requiredIf: requiredIf(computed(() => clientAccess.linkTypeId === 3)),
      },
      emailId: {
        requiredIf: requiredIf(computed(() => clientAccess.isSendEmail)),
      },
      text: {
        requiredIf: requiredIf(computed(() => clientAccess.isSendEmail)),
      },
    }

    const v$ = useVuelidate(rules, clientAccess as ClientAccess)

    const createAccess = () => {
      v$.value.$validate()

      if (!v$.value.$invalid) {
        return axios.post(urlClientAccessCreate(), clientAccess).then(res => {
          Object.assign(clientAccess, res.data)
          action.value = 'preview'
          props.dialog.dialogData.createHandler(res.data)
        })
      }
    }
    const deleteAccess = () => {
      handleAsync('deleteAccess', () => {
        return axios.delete(urlClientAccessDelete(clientAccess.id)).then(() => {
          props.dialog.dialogData.removeHandler(clientAccess)
          props.dialog.close()
        })
      })
    }

    const link = computed(() => {
      let token: string
      if (clientAccess.routeToken) {
        token = `?accessToken=${clientAccess.routeToken}`
      }
      if (clientAccess.token?.token) {
        token = `?authToken=${clientAccess.token?.token}`
      }
      return `${location.origin}/share-document/${clientAccess.id}${token}`
    })

    const isTermOver = computed<boolean>(() => {
      const currentDate: Date = new Date()
      const endDate: Date = new CustomDate(clientAccess.endDate).date.toDate()

      return endDate.getTime() < currentDate.getTime()
    })

    // TODO
    const canSetSignDocument = computed(() => {
      return true
      // return props.dialog.dialogData.document.actionId === 2
    })

    watch(
      computed(() => clientAccess.linkTypeId),
      () => {
        if (clientAccess.linkTypeId !== 3) {
          clientAccess.phoneId = null
          v$.value.phoneId.$reset()
        }
        if (clientAccess.linkTypeId === 1) {
          clientAccess.isSendEmail = false
        }
        if ([2, 3].includes(clientAccess.linkTypeId)) {
          clientAccess.isSendEmail = true
        }
      }
    )
    watch(
      computed(() => clientAccess.isSendEmail),
      v => {
        v$.value.emailId.$reset()
        if (!v) {
          clientAccess.emailId = null
        }
      }
    )
    return {
      clientAccess,
      v$,
      isPreviewMode,
      action,
      createAccess,
      deleteAccess,
      link,
      isTermOver,
      canSetSignDocument,
    }
  },
}
</script>

<style lang="scss" scoped></style>
