<template>
  <div>
    <div class="d-flex flex-row mb-5">
      <div class="block">
        <v-tabs v-model="tab" color="primary" direction="vertical" hide-slider>
          <v-tab class="mb-2" value="document-type">
            <v-icon start>mdi-swap-vertical</v-icon>
            Типи документів
          </v-tab>
          <v-tab class="mb-2" value="requisites">
            <v-icon start>mdi-file-document-multiple-outline</v-icon>
            Загальні реквізити
          </v-tab>
          <v-tab class="mb-2" value="contractors">
            <v-icon start>mdi-badge-account-outline</v-icon>
            Контрагенти
          </v-tab>
          <v-tab
            v-if="documentAction === 'signature'"
            class="mb-2"
            value="users">
            <v-icon start>mdi-badge-account-outline</v-icon>
            Співробітники
          </v-tab>
        </v-tabs>
      </div>

      <div class="w-100 ml-4">
        <v-window v-model="tab">
          <v-window-item value="document-type">
            <b-select
              v-model="document.typeId"
              :error-messages="getErrorMessages(v$.typeId)"
              :items="$directory.get('documentTypes', document.type)"
              :loading="$loading.isLoading('documentTypes')"
              class="mb-1"
              item-title="name"
              item-value="id"
              label="Тип документу"
              placeholder="Оберіть зі списку"
              @blur="v$.typeId.$touch()"
              @focus="$directory.fill('documentTypes')">
            </b-select>
          </v-window-item>
          <v-window-item value="requisites">
            <b-input
              v-model="document.name"
              :error-messages="getErrorMessages(v$.name)"
              class="mb-1"
              label="Назва документу"
              placeholder="Введіть текст"
              @blur="v$.name.$touch()">
            </b-input>
            <b-input
              v-model="document.number"
              :error-messages="getErrorMessages(v$.number)"
              :readonly="dialog.action === 'update'"
              class="mb-1"
              label="Номер документу"
              placeholder="Введіть номер"
              @blur="v$.number.$touch()">
            </b-input>
          </v-window-item>
          <v-window-item value="contractors">
            <b-form
              v-if="createAction"
              :submit="pushContractor"
              hide-action-buttons>
              <b-autocomplete
                v-model="selectedContractor"
                :error-messages="getErrorMessages(v$.contractors)"
                :item-title="
                  contractor => `${contractor?.name} (${contractor?.code})`
                "
                :items="contractorList"
                :loading="$loading.isLoading('searchContractors')"
                class="mb-1"
                item-value="id"
                label="Контрагент"
                placeholder="назва контрагенту/ЄДРПОУ"
                return-object
                @blur="v$.contractors.$touch()"
                @update:search="searchContractors">
                <template #append>
                  <v-btn
                    :disabled="
                      documentAction === 'agreement' &&
                      !!document.contractors.length
                    "
                    type="submit"
                    >Додати
                  </v-btn>
                </template>
              </b-autocomplete>
            </b-form>

            <v-card
              v-for="contractor in document.contractors"
              :key="contractor.id"
              class="mb-3"
              variant="tonal">
              <v-card-text>
                <div class="d-flex align-center justify-space-between">
                  <div style="font-size: 1rem">
                    <div>
                      <div class="text-disabled mb-3">
                        {{ contractor.code }}
                      </div>
                      <div>{{ contractor.name }}</div>
                    </div>
                  </div>
                  <div>
                    <v-btn
                      v-show="createAction"
                      color="error"
                      density="compact"
                      icon="mdi-delete"
                      rounded="circle"
                      variant="outlined"
                      @click="removeContractor(contractor.id)"></v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <div
              v-if="!document.contractors?.length"
              class="text-disabled text-center">
              Контрагенти відсутні
            </div>
          </v-window-item>
          <v-window-item value="users">
            <b-form v-if="createAction" :submit="pushUser" hide-action-buttons>
              <b-autocomplete
                v-model="selectedUser"
                :error-messages="getErrorMessages(v$.users)"
                :items="$directory.get('userList')"
                :loading="$loading.isLoading('userList')"
                class="mb-1"
                item-title="name"
                item-value="id"
                label="Співробітник"
                placeholder="Оберіть зі списку"
                return-object
                @blur="v$.users.$touch()"
                @focus="$directory.fill('userList')">
                <template #append>
                  <v-btn type="submit">Додати</v-btn>
                </template>
              </b-autocomplete>
            </b-form>

            <v-card
              v-for="user in document.users"
              :key="user.id"
              class="mb-3"
              variant="tonal">
              <v-card-text>
                <div class="d-flex align-center justify-space-between">
                  <div style="font-size: 1rem">
                    <div class="text-disabled mb-3">{{ user.post }}</div>
                    <div>{{ user.name }}</div>
                  </div>
                  <div class="d-flex align-center gap-3">
                    <v-switch
                      v-model="user.isSignature"
                      :readonly="!createAction"
                      color="primary"
                      hide-details
                      label="Підписує"></v-switch>
                    <v-btn
                      v-if="createAction"
                      color="error"
                      density="compact"
                      icon="mdi-delete"
                      rounded="circle"
                      variant="outlined"
                      @click="removeUser(user.id)"></v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <div
              v-if="!document.users?.length"
              class="text-disabled text-center">
              Співробітники відсутні
            </div>
          </v-window-item>
        </v-window>
        <FilesUploader
          v-if="createAction"
          v-model="document.document"
          :error-messages="getErrorMessages(v$.document)"
          :multiple="false"
          :readonly="readonly"
          :v$="v$.document"
          class="mb-5 mt-3"
          dropper
          label="Оберіть документи"
          main-folder="Документы"
          name-folder="template" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  axios,
  minLength,
  required,
  useStore,
  useVuelidate,
} from '@/plugins/index.js'
import { computed, ref } from 'vue'
import {
  urlContractorList,
  urlDocumentAgreementCreate,
  urlDocumentAgreementUpdate,
  urlDocumentSignatureCreate,
  urlDocumentSignatureUpdate,
} from '@/utils/urls.js'
import { cachedObject } from 'best-modules/utils'
import { getErrorMessages, getUserName } from '@/utils/helpers.js'
import FilesUploader from '@/components/filesUploader/FilesUploader.vue'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { cloneDeep, debounce, pick } from 'lodash'
import { fillDirectory } from '@/directory'
import { BForm } from 'best-modules/components'

export default {
  components: {
    FilesUploader,
    BForm,
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  async setup(props) {
    const store = useStore()
    const createAction = props.dialog.action === 'create'
    const userId = computed(() => store.state.user.userData?.id)
    const tab = ref('document-type')
    const documentAction = props.dialog.dialogData.documentAction

    const document = cachedObject({
      id: void [0],
      authorId: void [0],
      status: void [0],
      name: null,
      number: null,
      document: null,
      action: void [0],
      typeId: null,
      type: void [0],
      contractors: [],
      users: [],
    })
    const readonly = computed(() => {
      if (props.dialog.action === 'create') {
        return false
      } else {
        const isAuthor = document.authorId === userId.value
        return document.status?.id !== 1 || !isAuthor
      }
    })

    if (createAction) {
      await fillDirectory('documentTypes')
    } else {
      document.$set({
        ...(props.dialog.dialogData.document || {}),
        users: (props.dialog.dialogData.document?.users || []).map(u => {
          return {
            ...pick(u.user, ['id', 'post', 'department']),
            name: getUserName(u.user),
            isSignature: u.isSignature,
          }
        }),
        contractors: (props.dialog.dialogData.document?.contractors || []).map(
          c => {
            return pick(c.contractor, ['id', 'name', 'code'])
          }
        ),
      })
    }

    const rules = computed(() => {
      return {
        typeId: { required },
        name: { required },
        number: { required },
        document: { required },
        contractors: { required, minLength: minLength(1) },
        users:
          documentAction === 'signature'
            ? { required, minLength: minLength(1) }
            : {},
      }
    })
    const v$ = useVuelidate(rules, document)

    const submit = async () => {
      const getUrl = () => {
        switch (true) {
          case createAction && documentAction === 'agreement':
            return urlDocumentAgreementCreate()
          case createAction && documentAction === 'signature':
            return urlDocumentSignatureCreate()
          case !createAction && documentAction === 'agreement':
            return urlDocumentAgreementUpdate(document.id)
          case !createAction && documentAction === 'signature':
            return urlDocumentSignatureUpdate(document.id)
        }
      }
      const successMsgAction = createAction ? 'успішно створено' : 'оновлено'

      const getRequestObject = () => {
        const d = cloneDeep(document.$object)
        d.users = d.users.map(u => {
          return {
            id: u.id,
            isSignature: u.isSignature,
          }
        })
        d.contractors = d.contractors.map(c => c.id)

        return d
      }

      return axios.post(getUrl(), getRequestObject()).then(res => {
        setSnackbar({ text: `Документ ${successMsgAction}` })

        return res
      })
    }

    const disabled = computed(() => {
      return (!createAction && !document.$hasChanges) || readonly
    })

    const selectedContractor = ref(null)
    const contractorList = ref([])

    const searchContractors = debounce((search: string) => {
      handleAsync('searchContractors', () => {
        if (search && !selectedContractor.value) {
          return axios.get(urlContractorList({ search })).then(res => {
            contractorList.value = res.data
          })
        } else {
          contractorList.value = []
        }
      })
    }, 500)
    const pushContractor = (): void => {
      if (
        selectedContractor.value &&
        !document.contractors
          .map(c => c.id)
          .includes(selectedContractor.value.id)
      ) {
        document.contractors.push(selectedContractor.value)
        selectedContractor.value = null
      }
    }
    const removeContractor = (contractorId: number): void => {
      document.contractors = document.contractors.filter(
        c => c.id !== contractorId
      )
    }

    const selectedUser = ref(null)
    const pushUser = (): void => {
      if (
        selectedUser.value &&
        !document.users.map(u => u.id).includes(selectedUser.value.id)
      ) {
        document.users.push({ ...selectedUser.value, isSignature: false })
        selectedUser.value = null
      }
    }
    const removeUser = (userId: number): void => {
      document.users = document.users.filter(u => u.id !== userId)
    }

    return {
      v$,
      userId,
      document,
      createAction,
      getUserName,
      submit,
      disabled,
      readonly,
      tab,
      selectedContractor,
      contractorList,
      searchContractors,
      pushContractor,
      removeContractor,
      selectedUser,
      pushUser,
      removeUser,
      documentAction,
    }
  },
}
</script>

<style lang="scss" scoped>
.block {
  background: #f4f6f8;
  padding: 15px 8px;
  border: 1px dashed #80808059;
}
</style>
