<template>
  <div>
    <v-row align="center">
      <v-col cols="12">
        <b-label>
          <template #default="{ props }">
            <div
              class="d-flex align-center justify-space-between"
              v-bind="props">
              <span>АЦСК</span>
              <div class="d-flex align-center gap-3">
                <span>Визначати автоматично</span>
                <v-switch
                  v-model="isAutoisAutoCaAddress"
                  color="secondary"
                  hide-details></v-switch>
              </div>
            </div>
          </template>
        </b-label>
        <b-select
          v-model="privateKeyData.caAddress"
          :item-title="ca => ca.issuerCNs[0]"
          :items="[...caList]"
          :placeholder="
            isAutoisAutoCaAddress
              ? 'Визначати автоматично'
              : 'Оберіть зі списку'
          "
          item-value="address"
          @update:model-value="isAutoisAutoCaAddress = false"></b-select>
      </v-col>
      <v-col v-if="keyType === 'media'" cols="12">
        <b-select
          v-model="privateKeyData.privateKey"
          :error-messages="getErrorMessages(v$.privateKey)"
          :items="keyMedias"
          :return-object="true"
          item-title="visibleName"
          label="Носій ос. ключа"
          placeholder="Оберіть носій ос. ключа"
          @blur="v$.privateKey.$touch()"></b-select>
      </v-col>
      <v-col v-if="keyType === 'file'" cols="9">
        <b-input
          v-if="keyType === 'file'"
          :error-messages="getErrorMessages(v$.privateKey)"
          :model-value="privateKeyData.privateKey?.name"
          :readonly="true"
          label="Особистий ключ"
          placeholder="dat (key-6.dat), zs2, sk, jks, pk8, pfx"
          @blur="v$.privateKey.$touch()"></b-input>
      </v-col>
      <v-col v-if="keyType === 'file'" cols="3">
        <v-btn
          :disabled="!!keyInfo"
          class="w-100"
          color="secondary"
          @click="selectPrivateKey">
          Обрати
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="9">
        <b-input-password
          v-model="privateKeyData.password"
          :error-messages="getErrorMessages(v$.password)"
          :readonly="!!keyInfo"
          label="Пароль"
          @blur="v$.password.$touch()"></b-input-password>
      </v-col>
      <v-col cols="3">
        <v-btn
          v-if="!!keyInfo"
          class="w-100"
          color="error"
          @click="clearSignature">
          Вилучити
        </v-btn>
        <v-btn
          v-else
          :loading="$loading.isLoading(`readPrivateKey__${id}`)"
          class="w-100"
          color="secondary"
          @click="readPrivateKey">
          Зчитати
        </v-btn>
      </v-col>
    </v-row>

    <!-- TODO -->
    <div v-if="!stamp" v-show="false" class="mt-5">
      <v-checkbox
        :model-value="true"
        color="primary"
        disabled
        hide-details
        style="margin-left: -10px">
        <template #label>
          <span class="checkbox-label">
            Запам'ятовувати ключі в цьому браузері
          </span>
        </template>
      </v-checkbox>
    </div>

    <div v-if="keyInfo" class="mt-2">
      <key-info :keyInfo="keyInfo"></key-info>
    </div>

    <div v-if="typeof isValidSignature === 'boolean'" class="mt-6">
      <span v-if="isValidSignature" class="text-success">
        {{ stamp ? 'Печатку' : 'Ключ' }} успішно зчитано
      </span>
      <span v-else class="text-error">
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { caList } from '@/utils/signature/caList'
import { BInputPassword, BLabel, selectFile } from 'best-modules/components'
import KeyInfo from './KeyInfo.vue'
import { generateId, getErrorMessages } from 'best-modules/utils'
import { required, useVuelidate } from '@/plugins/vuelidate.js'
import { onBeforeUnmount, PropType, reactive, ref, watch } from 'vue'
import { handleAsync } from 'best-modules/plugins'
import {
  keyMedias,
  readPrivateKeyFile,
  readPrivateKeyMedia,
  removeKey,
} from '@/utils/signature'
import { KeyType } from '../types'

export default {
  name: 'KeyReader',
  components: { KeyInfo, BInputPassword, BLabel },
  props: {
    keyType: {
      type: String as PropType<KeyType>,
    },
    stamp: {
      type: Boolean as PropType<boolean>,
    },
  },
  data: () => {
    return {
      caList,
    }
  },
  methods: { getErrorMessages },
  setup(props) {
    const id: string = generateId()
    const isAutoisAutoCaAddress = ref<boolean>(true)
    const keyInfo = ref(null)
    const isValidSignature = ref(null)
    const error = ref(null)

    const privateKeyData = reactive({
      privateKey: null,
      password: null,
      caAddress: null,
    })

    const rules = {
      privateKey: { required },
      password: { required },
    }
    const v$ = useVuelidate(rules, privateKeyData)

    const selectPrivateKey = async () => {
      const { blob } = await selectFile({
        accept: ['.dat', '.zs2', '.sk', '.jks', '.pk8', '.pfx'],
        multiple: false,
      })

      privateKeyData.privateKey = blob[0]
    }

    const clearSignature = () => {
      privateKeyData.privateKey = null
      isValidSignature.value = null
      privateKeyData.password = null
      keyInfo.value = null
      removeKey(id)
    }

    const readPrivateKey = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return handleAsync(`readPrivateKey__${id}`, async () => {
          const read =
            props.keyType === 'media' ? readPrivateKeyMedia : readPrivateKeyFile

          console.log('privateKey', privateKeyData.privateKey)

          try {
            const { ownerInfo } = await read(
              id,
              privateKeyData.privateKey,
              privateKeyData.password,
              privateKeyData.caAddress
            )
            if (
              props.stamp &&
              !['Цифрова печатка', '1.2.804.2.1.1.1.3.9'].includes(
                ownerInfo.extKeyUsages
              )
            ) {
              removeKey(id)
              throw new Error(
                'Ви завантажили ключ підпису. Будь ласка, оберіть ключ печатки.'
              )
            }
            keyInfo.value = ownerInfo
            isValidSignature.value = true
            error.value
          } catch (e) {
            console.log(e)
            error.value = e?.message || e
            isValidSignature.value = false
          }
        })
      }
    }

    watch(isAutoisAutoCaAddress, async value => {
      if (value) {
        privateKeyData.caAddress = null
      } else {
        if (!privateKeyData.caAddress) {
          privateKeyData.caAddress = [...caList][0].address
        }
      }
    })

    onBeforeUnmount(() => {
      removeKey(id)
    })

    return {
      privateKeyData,
      v$,
      readPrivateKey,
      isValidSignature,
      error,
      keyInfo,
      selectPrivateKey,
      clearSignature,
      isAutoisAutoCaAddress,
      id,
      keyMedias,
    }
  },
}
</script>

<style lang="scss" scoped></style>
