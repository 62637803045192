import { ref } from 'vue'
import { debounce } from 'lodash'
import axios from '@/plugins/axios/index.js'
import { urlGeneralSearch } from '@/utils/urls.js'
import { handleAsync } from 'best-modules/plugins'

export function useGlobalSearch() {
  const routeMap = {
    incomingDocuments: {
      route: 'document-agreement-single',
      title: 'Вхідні документи',
    },
    outgoingDocuments: {
      route: 'document-agreement-single',
      title: 'Вихідні документи',
    },
    documentAgreements: {
      route: 'document-agreement-single',
      title: 'Документи для підписання',
    },
    documentSignature: {
      route: 'document-signature-single',
      title: 'Документи для візування',
    },
    incomingTasks: {
      route: 'single-task',
      title: 'Вхідні завдання',
    },
    outgoingTasks: {
      route: 'single-task',
      title: 'Вихідні завдання',
    },
    orders: {
      route: 'single-order',
      title: 'Накази',
    },
    contractors: {
      route: 'single-contractor',
      title: 'Контрагенти',
    },
    tasks: {
      route: 'single-task',
      title: 'Завдання',
    },
  }

  /** Search section */
  const searchRenderKey = ref(1)
  const searchItems = ref([])

  const getSearchItems = debounce(search => {
    if (search) {
      return handleAsync('getSearchRequest', () => {
        return axios.get(urlGeneralSearch(search)).then(res => {
          const items = []
          for (const f in res.data) {
            if (!(f in res.data)) {
              console.warn(
                `global search - global search result has unhandled key "${f}"`
              )
              continue
            }
            if (res.data[f].length) {
              items.push({
                title: routeMap[f].title,
                value: f,
                props: { disabled: true },
              })
              items.push(
                ...res.data[f].map(el => {
                  return {
                    title: el.name || el.topic,
                    value: `${routeMap[f].route}-${el.id}`,
                    props: {
                      to: { name: routeMap[f].route, params: { id: el.id } },
                    },
                  }
                })
              )
            }
          }
          searchItems.value = items
        })
      })
    } else {
      searchItems.value = []
    }
  }, 600)

  return { searchItems, getSearchItems, searchRenderKey }
}
