<template>
  <div v-for="dialog in dialogs" :key="dialog.id">
    <v-dialog
      v-model="dialog.isOpen"
      :width="
        (components[dialog.name] && components[dialog.name].width) ||
        defaultWidth
      "
      persistent
      style="z-index: 1999">
      <v-card v-if="dialog.isOpen" class="cursor-default">
        <v-card-text class="pt-0 pb-0">
          <v-toolbar color="transparent" density="comfortable">
            <div class="w-100 d-flex justify-space-between align-center">
              <v-toolbar-title class="font-weight-bold" style="font-size: 18px">
                <div>
                  {{
                    dialog.params.title || components[dialog.name].title(dialog)
                  }}
                </div>
                <div v-if="components[dialog.name].subtitle">
                  {{
                    dialog.params.subtitle ||
                    components[dialog.name].subtitle(dialog)
                  }}
                </div>
              </v-toolbar-title>

              <v-btn density="comfortable" icon @click="closeDialog(dialog)">
                <v-icon color="#8E92BC" size="x-large"
                  >mdi-close-circle-outline
                </v-icon>
              </v-btn>
            </div>
          </v-toolbar>
        </v-card-text>
        <v-card-text>
          <v-form @submit.prevent>
            <div>
              <suspense @resolve="dialog.loading = false">
                <component
                  :is="components[dialog.name].component"
                  :ref="setDialogRef(dialog)"
                  :dialog="omit(dialog, ['ref'])" />

                <template #fallback>
                  <Skeleton />
                </template>
              </suspense>
              <submit-buttons
                v-if="!dialog.params.hideActionButtons"
                :cancel="() => closeDialog(dialog)"
                :cancel-label="dialog.params.cancelLabel"
                :disabled="
                  typeof dialog.ref?.disabled === 'boolean'
                    ? dialog.ref.disabled
                    : false
                "
                :submit="() => confirm(dialog)"
                :submit-label="dialog.params.submitLabel"
                class="mt-5" />
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import components from './components'
import { Dialog, useDialog } from './index'
import SubmitButtons from '@/components/SubmitButtons.vue'
import Skeleton from '@/components/Skeleton.vue'
import { omit } from 'lodash'

const defaultWidth = '460px'

export default {
  name: 'Dialog',
  components: {
    SubmitButtons,
    Skeleton,
  },
  methods: { omit },
  setup() {
    const { closeDialog, setDialogRef, dialogs } = useDialog()

    const confirm = async (dialog: Dialog) => {
      await dialog.ref.v$.$validate()

      if (!dialog.ref.v$.$error) {
        try {
          const res = await dialog.ref.submit()
          typeof dialog.params.cb === 'function' &&
            dialog.params.cb(res?.data || res)

          closeDialog(dialog)
        } catch (e) {
          throw new Error(
            `
              dialog: ${dialog.name}.vue has unexpected error on submit action
              Error: ${e}
            `
          )
        }
      }
      if (typeof dialog.ref.validationHandler === 'function') {
        dialog.ref.validationHandler()
      }
    }

    return {
      components,
      dialogs,
      confirm,
      defaultWidth,
      closeDialog,
      setDialogRef,
    }
  },
}
</script>

<style scoped></style>
