<template>
  <div>
    <v-dialog v-model="dialog" content-class="dialog-top" max-width="600">
      <template #activator="{ props }">
        <v-btn
          :icon="true"
          rounded="circle"
          style="background: #f2f7ff"
          v-bind="props"
          variant="text">
          <v-icon color="#8E92BC">mdi-magnify</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <b-autocomplete
            :key="searchRenderKey"
            :items="searchItems"
            :loading="$loading.isLoading('getSearchRequest')"
            :menu-props="{ width: '600px' }"
            hide-details
            item-title="title"
            item-value="value"
            no-filter
            placeholder="Пошук"
            style="min-width: 12rem"
            @update:search="getSearchItems"
            @update:model-value="searchRenderKey++">
            <template #prepend-inner>
              <v-icon class="mt-1" icon="mdi-magnify" />
            </template>
            <template #append-inner>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                  v-if="$loading.isLoading('getSearchRequest')"
                  color="primary"
                  indeterminate
                  size="24">
                </v-progress-circular>
              </v-fade-transition>
            </template>
          </b-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useGlobalSearch } from '@/components/the-header/globalSearch'

export default {
  name: 'SearchDialog',
  setup() {
    const dialog = ref(false)
    const route = useRoute()

    watch(route, () => {
      if (dialog.value) {
        dialog.value = false
      }
    })

    return {
      dialog,
      ...useGlobalSearch(),
    }
  },
}
</script>
