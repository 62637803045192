<template>
  <v-card>
    <v-card-text>
      <Skeleton v-if="loading" />
      <div v-else>
        <div class="justify-space-between mb-5">
          <div class="d-flex align-center justify-space-between">
            <div>
              <span class="font-weight-bold d-block" style="font-size: 24px">{{
                getUserName(user.$object)
              }}</span>

              <v-tabs
                v-model="activeTab"
                class="mt-4 mb-4"
                hide-slider
                style="border-bottom: 1px solid #e0e4e7">
                <v-tab>Загальна інформація</v-tab>
                <v-tab>Відпустки</v-tab>
              </v-tabs>
            </div>
            <div>
              <v-btn
                :disabled="!!user.ermId || readonly"
                :loading="sendToErmLoading"
                outline
                @click="sendToErm">
                <v-icon v-if="!!user.ermId" class="mr-2"
                  >{{ 'mdi-transit-connection-variant' }}
                </v-icon>
                {{ !!user.ermId ? 'Звязано з ЕРМ' : ' Відправити в ERM' }}
              </v-btn>
            </div>
          </div>

          <div v-if="activeTab === 0">
            <v-row>
              <v-col cols="12" md="4" sm="12">
                <div class="pa-3 h-100" style="border: 1px solid #e0e4e7">
                  <div class="d-flex justify-space-between">
                    <div>
                      <UserPhoto
                        v-model="user.photo"
                        :readonly="readonly"
                        :user="user.$object" />
                    </div>
                    <div>
                      <v-switch
                        v-model="user.isActive"
                        :label="user.isActive ? 'Активний' : 'Звільнений'"
                        :loading="activateLoading"
                        :readonly="readonly"
                        class="align-self-start"
                        color="primary"
                        hide-details
                        @update:model-value="handleActive"></v-switch>
                    </div>
                  </div>
                  <v-row class="mt-5">
                    <v-col cols="12" md="12" sm="12">
                      <v-switch
                        v-model="user.isNotificationEmail"
                        :readonly="readonly"
                        class="w-100 pb-0"
                        color="primary"
                        hide-details
                        label="Сповіщення на пошту" />
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-switch
                        v-model="user.isNotificationTelegram"
                        :readonly="readonly"
                        class="w-100 pb-0"
                        color="primary"
                        hide-details
                        label="Сповіщення в telegram" />
                    </v-col>
                  </v-row>
                  <div class="mt-6">
                    <v-btn
                      v-if="!changePassword && !readonly"
                      style="padding: 0 !important; letter-spacing: -0.26px"
                      variant="text"
                      @click="toggleChangePassword"
                      >Змінити пароль
                    </v-btn>
                    <v-btn
                      v-if="changePassword"
                      style="padding: 0 !important; letter-spacing: -0.26px"
                      variant="text"
                      @click="toggleChangePassword">
                      Скасувати зміну пароля
                    </v-btn>
                    <b-input
                      v-if="changePassword"
                      v-model="user.password"
                      placeholder="Введіть новий пароль"></b-input>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="8" sm="12">
                <div class="badge blue mb-3">Співробітник</div>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <b-input
                      v-model="user.secondName"
                      :error-messages="getErrorMessages(v$.secondName)"
                      class="w-100"
                      hide-details
                      label="Прізвище"
                      placeholder="Введіть текст"
                      @blur="v$.secondName.$touch()"></b-input>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <b-input
                      v-model="user.name"
                      :error-messages="getErrorMessages(v$.name)"
                      class="w-100"
                      hide-details
                      label="Ім'я"
                      placeholder="Введіть текст"
                      @blur="v$.name.$touch()"></b-input>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <b-input
                      v-model="user.patronymic"
                      :error-messages="getErrorMessages(v$.patronymic)"
                      class="w-100"
                      hide-details
                      label="По батькові"
                      placeholder="Введіть текст"
                      @blur="v$.patronymic.$touch()"></b-input>
                  </v-col>
                </v-row>
                <div class="badge yellow mt-5 mb-3">Де працює</div>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <div class="d-flex align-end">
                      <b-input
                        :model-value="
                          user.department ? user.department.name : 'Не вказано'
                        "
                        class="w-100"
                        hide-details
                        label="Департамент"
                        placeholder="Введіть текст"
                        readonly>
                      </b-input>
                      <v-btn
                        :disabled="readonly"
                        class="ml-2"
                        icon
                        size="x-small"
                        @click="changeDepartment">
                        <v-icon>{{ 'mdi-swap-horizontal' }}</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <b-select
                      v-model="user.roleId"
                      :error-messages="getErrorMessages(v$.roleId)"
                      :items="$directory.get('roles', user.role)"
                      :loading="$loading.isLoading('roles')"
                      class="w-100"
                      hide-details
                      item-title="name"
                      item-value="id"
                      label="Роль"
                      placeholder="Оберіть зі списку"
                      @blur="v$.roleId.$touch()"
                      @focus="$directory.fill('roles')"></b-select>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <b-input
                      v-model="user.post"
                      :error-messages="getErrorMessages(v$.post)"
                      class="w-100"
                      hide-details
                      label="Посада"
                      placeholder="Введіть текст"
                      @blur="v$.post.$touch()"></b-input>
                  </v-col>
                </v-row>
                <div class="badge green mt-5 mb-3">Особисті дані</div>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <b-input
                      v-model="user.inn"
                      v-mask:[innMask]
                      :error-messages="getErrorMessages(v$.inn)"
                      class="w-100"
                      hide-details
                      label="ІПН"
                      placeholder="Введіть номер"
                      type="mask"
                      @blur="v$.inn.$touch()"></b-input>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <b-select
                      v-model="user.genderId"
                      :error-messages="getErrorMessages(v$.genderId)"
                      :items="$directory.get('genders', user.gender)"
                      :loading="$loading.isLoading('genders')"
                      class="w-100"
                      hide-details
                      item-title="name"
                      item-value="id"
                      label="Стать"
                      placeholder="Оберіть зі списку"
                      @blur="v$.genderId.$touch()"
                      @focus="$directory.fill('genders')"></b-select>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <BaseDatePicker
                      v-model="user.birthDate"
                      :error-messages="getErrorMessages(v$.birthDate)"
                      hide-details
                      label="Дата народження"
                      @blur="v$.birthDate.$touch()" />
                  </v-col>
                </v-row>
                <div class="badge purple mt-5 mb-3">Контакти</div>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <b-input-phone
                      v-model="user.phone"
                      :error-messages="getErrorMessages(v$.phone)"
                      hide-details
                      label="Телефон"
                      @blur="v$.phone.$touch()"></b-input-phone>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <b-input
                      v-model="user.email"
                      v-mask:[emailMask]
                      :error-messages="getErrorMessages(v$.email)"
                      class="w-100"
                      hide-details
                      label="Пошта"
                      placeholder="Введіть текст"
                      @blur="v$.email.$touch()"></b-input>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <b-input
                      v-model="user.profileDWH"
                      :error-messages="getErrorMessages(v$.profileDWH)"
                      class="w-100"
                      hide-details
                      label="profileDWH"
                      placeholder="Введіть текст"
                      @blur="v$.profileDWH.$touch()"></b-input>
                  </v-col>
                </v-row>
                <v-row class="mt-6">
                  <v-col cols="12" md="8" sm="12">
                    <div class="info">
                      <span>Дата створення: {{ user.created_at }}</span>
                      <span>Дата оновлення: {{ user.updated_at }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="d-flex justify-end flex-column"
                    cols="12"
                    md="4"
                    sm="12">
                    <SubmitButtons
                      :cancel="() => user.$reset()"
                      :disabled="readonly || !user.$hasChanges"
                      :submit="submit" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-if="activeTab === 1">
            <UserAbsence
              v-model="user.absences"
              :user="user"
              class="mb-4"></UserAbsence>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { emailMask, innMask } from '@/utils/masks.js'
import UserPhoto from './UserPhoto.vue'
import UserAbsence from './UserAbsence.vue'
import Skeleton from '@/components/Skeleton.vue'
import { BInputPhone } from 'best-modules/components'
import SubmitButtons from '@/components/SubmitButtons.vue'
import {
  axios,
  useBreadcrumb,
  useRoute,
  useStore,
  useVuelidate,
} from '@/plugins'
import {
  urlUserActivate,
  urlUserDeactivate,
  urlUserSendToErm,
  urlUserSingle,
  urlUserUpdate,
} from '@/utils/urls.js'
import { cachedObject } from 'best-modules/utils'
import { computed, provide, ref } from 'vue'
import { getErrorMessages, getUserName } from '@/utils/helpers.js'
import { getUserRules } from '@/utils/validations.js'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { openDialog } from '@/dialog'
import { setSnackbar } from 'best-modules/plugins'

export default {
  components: {
    BaseDatePicker,
    Skeleton,
    UserPhoto,
    UserAbsence,
    SubmitButtons,
    BInputPhone,
  },
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    emailMask() {
      return emailMask
    },
  },
  methods: { getErrorMessages },
  setup() {
    const store = useStore()
    const route = useRoute()
    const loading = ref(true)
    const activateLoading = ref(false)
    const sendToErmLoading = ref(false)
    const breadcrumb = useBreadcrumb()

    const readonly = computed(() => store.state.user.userData?.roleId !== 1)
    provide('readonly', readonly)

    const changePassword = ref(false)
    const toggleChangePassword = () => {
      if (changePassword.value) {
        changePassword.value = false
        delete user.password
      } else {
        changePassword.value = true
      }
    }

    const user = cachedObject({})
    user.$setIgnore(
      'isActive',
      'ermId',
      'absences',
      'department',
      'departmentId'
    )

    const v$ = useVuelidate(getUserRules(), user)

    const setUser = obj => {
      user.$set(obj)

      breadcrumb.set([
        {
          title: 'Співробітники',
          to: { name: 'users' },
          index: 0,
        },
        {
          title: getUserName(user.$object),
          index: 1,
        },
      ])
    }

    const getUser = () => {
      return axios.get(urlUserSingle(route.params.id)).then(res => {
        setUser(res.data)

        loading.value = false

        return res.data
      })
    }

    getUser()

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return axios.post(urlUserUpdate(user.id), user.$object).then(res => {
          setUser(res.data)
          changePassword.value = false

          return res.data
        })
      }
    }

    const handleActive = isActive => {
      activateLoading.value = true
      const url = isActive ? urlUserActivate : urlUserDeactivate
      return axios
        .get(url(user.id))
        .catch(() => {
          user.isActive = !isActive
        })
        .finally(() => {
          activateLoading.value = false
        })
    }

    const sendToErm = () => {
      sendToErmLoading.value = true
      return axios
        .get(urlUserSendToErm(user.id))
        .then(() => {
          loading.value = true
          getUser()
          setSnackbar({ text: 'Користувача успішно відправлено в ЕРМ' })
        })
        .catch(() => {
          setSnackbar({
            text: 'Виникла непередбачувана помилка',
            color: 'error',
          })
        })
        .finally(() => {
          sendToErmLoading.value = false
        })
    }

    const changeDepartment = () => {
      openDialog({
        name: 'ChangeDepartment',
        action: 'update',
        params: {
          cb: data => {
            if (data) {
              user.department = data
              user.departmentId = data.id
            }
          },
        },
        dialogData: {
          userId: user?.id,
          departmentId: user?.departmentId,
        },
      })
    }

    return {
      loading,
      user,
      store,
      getUserName,
      submit,
      v$,
      innMask,
      changePassword,
      toggleChangePassword,
      handleActive,
      activateLoading,
      sendToErm,
      sendToErmLoading,
      readonly,
      changeDepartment,
    }
  },
}
</script>

<style lang="scss" scoped>
.badge {
  padding: 3px 8px;
  max-width: fit-content;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
}

.blue {
  background: #c7e2ff;
}

.green {
  background: #e2fbd7;
}

.purple {
  background: #dad7fe;
}

.yellow {
  background: #fff5cc;
}

.info {
  background: #f5f6f8;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: fit-content;

  span {
    display: block;
    font-size: 12px;
    color: #b5b7c0;
    margin-bottom: 4px;
  }
}
</style>
