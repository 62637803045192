<template>
  <div v-if="localNotes.length">
    <note-item :note="localNotes.at(-1)" hideDelete>
      <template #showAllNotes>
        <v-btn
          size="small"
          style="padding: 0 !important"
          variant="text"
          @click="openNotesDialog"
          >Показати всі...
        </v-btn>
      </template>
    </note-item>
  </div>
  <div v-else>
    <div v-if="!disabled" class="wrapper" @click="openNotesDialog">
      <v-icon color="#49A2F4" icon="mdi-plus" />
      <span class="label d-block">Додати</span>
    </div>
    <span v-if="disabled" class="label">Дія неможлива</span>
  </div>
</template>

<script>
import NoteItem from '@/dialog/components/notes/NoteItem.vue'
import { ref } from 'vue'
import { openDialog } from '@/dialog/index.ts'

export default {
  components: { NoteItem },
  props: {
    notes: { type: [Array, Object], default: () => [] },
    entityId: { type: Number, required: true },
    entityTypeId: {
      type: Number,
      required: true,
      validator: val => [1, 2, 3].includes(val),
    },
    disabled: { type: Boolean },
  },
  setup(props) {
    const localNotes = ref(props.notes || [])

    const openNotesDialog = () => {
      openDialog({
        name: 'Notes',
        dialogData: {
          notes: localNotes.value,
          entityId: props.entityId,
          entityTypeId: props.entityTypeId,
          updateNotes: notes => (localNotes.value = notes),
        },
        params: {
          hideActionButtons: true,
        },
      })
    }
    return { openNotesDialog, localNotes }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 4px;
  border: 1px dashed #e0e4e7;
  padding: 16px 8px;
  text-align: center;
  transition: 0.3s;

  &:hover {
    background: #eefafc;
  }

  .label {
    color: #8e92bc;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
