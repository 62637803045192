5
<template>
  <li class="file" @click="openDocument(file.path)">
    <div class="file__icon">
      <Skeleton v-if="file.loading" :rows="1" height="2rem" width="2rem" />
      <v-icon v-else :icon="getIconName(file.path)" />
    </div>
    <div class="file__text">
      <div v-if="file.loading" class="file__info">
        <Skeleton :rows="1" class="mb-1" height="1.05rem" width="6rem" />
        <Skeleton :rows="1" height="1.05rem" width="3rem" />
      </div>
      <div v-else class="file__info">
        <!-- TODO -->
        <span class="file__name">{{ file.name }}</span>
        <span class="file__size">{{ formatSize(file.size) }}</span>
      </div>
      <div v-if="!disabled" class="file__actions">
        <v-menu v-model="menu" :close-on-content-click="false" location="end">
          <template #activator="{ props }">
            <v-btn
              icon
              size="x-small"
              style="background: transparent"
              v-bind="props"
              variant="text">
              <v-icon color="black">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>

          <v-card min-width="150">
            <div class="d-flex flex-column align-start">
              <div v-for="(btn, idx) in actions" :key="idx" class="pa-1 w-100">
                <v-btn
                  :loading="$loading.isLoading(btn.loader || 'empty')"
                  class="actionBtn"
                  variant="text"
                  @click="btn.command">
                  <v-icon class="mr-1">{{ btn.icon }}</v-icon>
                  {{ btn.label }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-menu>
      </div>
    </div>
  </li>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import { download, openDocument } from '@/utils/helpers.js'
import { ref } from 'vue'
import { handleAsync } from 'best-modules/plugins'

export default {
  components: { Skeleton },
  emits: ['remove-file'],
  props: {
    file: { type: Object },
    disabled: { type: Boolean },
  },
  setup(props, { emit }) {
    const menu = ref(false)
    const formatSize = bytes => {
      if (bytes === 0) {
        return '0 B'
      }

      const k = 1000,
        dm = 2,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }

    const getIconName = path => {
      console.log(path, 'path')
      const ext = path.split('.').at(-1)
      switch (true) {
        case ['doc', 'docx'].includes(ext):
          return 'best:doc'
        case ['xls', 'xlsx'].includes(ext):
          return 'best:xls'
        case ['zip', 'rar'].includes(ext):
          return 'best:zip'
        case ['pdf'].includes(ext):
          return 'best:pdf'
        case ['png'].includes(ext):
          return 'png'
        case ['jpg', 'jpeg'].includes(ext):
          return 'best:jpg'
      }
    }

    const actions = [
      {
        loader: `download_${props.file.path}`,
        label: 'Завантажити',
        command: () => {
          return handleAsync(`download_${props.file.path}`, () => {
            return download(props.file.path, props.file.name)
          })
        },
        icon: 'mdi-cloud-download',
        disabled: props.file.loading,
      },
      {
        loader: `open_${props.file.path}`,
        label: 'Переглянути',
        command: () => {
          return handleAsync(`open_${props.file.path}`, () => {
            return openDocument(props.file.path)
          })
        },
        icon: 'mdi-eye',
        disabled: props.file.loading,
      },
      {
        label: 'Видалити',
        command: () => emit('remove-file', props.file),
        icon: 'mdi-delete-forever',
        loader: `deleteFile__${props.file.path}`,
        disabled: props.disabled || props.file.loading,
      },
    ]

    return {
      formatSize,
      getIconName,
      download,
      openDocument,
      actions,
      menu,
    }
  },
}
</script>

<style lang="scss" scoped>
.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-top: 3px;
  transition: 0.3s background;
  cursor: default;

  &:hover {
    background: #eefafc;
  }

  &__icon {
    //width: 18px;
    //height: 18px;
    margin-right: 0.5rem;
  }

  &__text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__info > * {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #323232;
  }

  &__info > *:last-child {
    margin-bottom: 0;
  }

  &__size {
    color: #b5b7c0;
    font-size: 11px;
    font-weight: 600;
  }

  &__actions {
    display: flex;
    align-items: center;

    & .p-button {
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}

.actionBtn {
  background: transparent !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #323232 !important;
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  letter-spacing: -0.26px;
}
</style>
