<template>
  <div
    :class="{ 'wrapper--disabled': loading }"
    :style="{
      border: outline ? '1px solid #D4D4D8 !important' : '1px dashed #D4D4D8',
      borderBottom: 'none',
    }"
    class="wrapper"
    @click="handleSelect"
    @dragover.prevent
    @drop.prevent="handleDrop">
    <div class="uploader-icon mb-2">
      <v-progress-circular
        v-if="loading"
        :width="3"
        indeterminate
        size="small" />
      <v-btn v-else icon rounded="circle" size="small" variant="text">
        <v-icon
          color="black"
          icon="mdi-cloud-upload-outline"
          size="large"></v-icon>
      </v-btn>
    </div>
    <div
      v-if="dropperVariant === 'blue-label'"
      class="d-flex flex-column text-center align-center"
      style="max-width: 20rem">
      <span class="font-weight-bold mb-1">
        Виберіть файл або перетягніть його сюди
      </span>
      <span class="font-weight-light" style="font-size: 12px">
        {{ acceptedFilesText }}
      </span>
      <v-btn
        class="w-50 mt-3"
        color="white"
        density="compact"
        elevation="1"
        rounded="lg"
        variant="flat">
        Завантажити
      </v-btn>
    </div>
    <span v-else class="d-block">Завантажити файл</span>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { filesHandler, selectFile } from './selectFile'
import { SelectFileOptions } from './selectFile/types'

export type DropperVariant = 'default' | 'blue-label'

export default {
  name: 'FileDropper',
  emits: ['select'],
  props: {
    outline: {
      type: Boolean,
    },
    dropperVariant: {
      type: String as PropType<DropperVariant>,
      default: 'default',
    },
    selectOptions: {
      type: Object as PropType<SelectFileOptions>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: { filesHandler },
  setup(props, { emit }) {
    const handleSelect = () => {
      if (!props.loading) {
        selectFile(props.selectOptions).then(result => {
          emit('select', result)
        })
      } else return
    }
    const handleDrop = e => {
      filesHandler({
        fileList: e.dataTransfer.files,
        resolve: e => emit('select', e),
      })
    }

    const acceptedFilesText = computed(() => {
      const format = []
      let firstElement = ''

      if (props.selectOptions.accept && props.selectOptions.accept.length) {
        props.selectOptions.accept.forEach((item, index) => {
          if (index === 0) {
            firstElement = item.slice(1).toUpperCase()
          } else {
            format.push(item.slice(1).toUpperCase())
          }
        })

        return `${firstElement} або ${format.join(', ')} формати, до 5мб`
      }

      return ''
    })

    return { handleSelect, handleDrop, acceptedFilesText }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 4px 4px 0 0;
  padding: 16px 8px;
  text-align: center;
  transition: 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background: white;

  &--disabled {
    background: #fbfbfb;
    cursor: default;

    .label {
      display: none !important;
    }
  }

  .uploader-icon {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(.wrapper--disabled):hover {
    opacity: 0.8;
  }
}
</style>
