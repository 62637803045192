<template>
  <div>
    <v-breadcrumbs v-if="breadcrumbItems.length >= 2" :items="breadcrumbItems">
      <template #prepend>
        <!--        <v-icon class="mr-2" @click="$router.push({ name: 'home' })">-->
        <!--          mdi-home-->
        <!--        </v-icon>-->
        <div v-if="breadcrumbItems.length >= 2" class="d-flex align-center">
          <span
            class="mr-1 text-truncate cursor-pointer"
            @click="$router.push({ name: 'home' })">
            Головна
          </span>
          <v-icon class="mr-1" icon="mdi-chevron-right"></v-icon>
        </div>
      </template>
      <template #divider>
        <v-icon icon="mdi-chevron-right"></v-icon>
      </template>
      <template #item="{ item, index }">
        <div
          v-tooltip:bottom="item.title"
          class="text-truncate"
          style="max-width: 250px">
          <v-breadcrumbs-item
            v-if="item.title"
            :disabled="breadcrumbItems.length === index + 1 || item.disabled"
            :title="item.title"
            :to="breadcrumbItems.length === index + 1 ? null : item.to"
            class="cursor-pointer d-inline"></v-breadcrumbs-item>
        </div>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import { useBreadcrumb } from '@/plugins'
import { computed } from 'vue'

export default {
  setup() {
    const { items } = useBreadcrumb()
    const home = { icon: 'pi pi-home', href: { name: 'home' } }
    const breadcrumbItems = computed(() => items.value || [])
    return { home, breadcrumbItems }
  },
}
</script>

<style lang="scss" scoped></style>
