<template>
  <v-card elevation="1">
    <v-card-text>
      <b-server-table
        :key="renderKey"
        :headers="columns"
        :request-func="getUsers"
        show-filters
        show-search
        @click:row="openRow">
        <template #filters>
          <b-server-filter-item
            v-slot="{ props }"
            directory="departmentList"
            query-key="departmentId">
            <b-select
              clearable
              label="Департамент"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            directory="roles"
            query-key="departmentId">
            <b-select
              clearable
              label="Роль"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            class="d-flex align-end"
            query-key="isActive">
            <v-checkbox
              false-value="false"
              label="Активний"
              true-value="true"
              v-bind="props"></v-checkbox>
          </b-server-filter-item>
        </template>
        <template #action-button>
          <v-btn
            v-if="store.state.user.userData?.roleId === 1"
            rounded="lg"
            @click="createUser">
            <v-icon class="mr-1">mdi-plus-circle</v-icon>
            Створити співробітника
          </v-btn>
        </template>
        <template #bottom></template>
        <template #item.isActive="{ item }">
          {{ item.isActive ? 'Активний' : 'Звільнений' }}
        </template>
        <template #item.department="{ item }">
          {{ item.department?.name || '---' }}
        </template>
        <template #item.name="{ item }">
          {{ getUserName(item) || '---' }}
        </template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { useStore } from 'vuex'
import { axios } from '@/plugins/index.js'
import { ref } from 'vue'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { urlUserList } from '@/utils/urls.js'
import { openDialog } from '@/dialog/index.ts'
import { useRouter } from 'vue-router'
import { BServerFilterItem, BServerTable } from 'best-modules/components/index'

export default {
  name: 'UserList',
  components: {
    BServerTable,
    BServerFilterItem,
  },
  methods: { getUserName },
  setup() {
    const renderKey = ref(1)
    const store = useStore()
    const router = useRouter()
    const columns = ref([
      {
        key: 'isActive',
        title: 'Статус',
      },
      {
        key: 'name',
        title: 'ПІБ',
        width: 250,
      },
      {
        key: 'department',
        title: 'Департамент',
      },
      { key: 'post', title: 'Посада' },
    ])

    const openRow = event => {
      navigateToItem('single-user', event.id)
    }

    const getUsers = query => {
      return axios.get(urlUserList(query)).then(res => res.data)
    }

    const createUser = () => {
      openDialog({
        name: 'User',
        action: 'create',
        params: {
          cb: newUser => {
            router.push({ name: 'single-user', params: { id: newUser.id } })
          },
        },
      })
    }

    return {
      store,
      openRow,
      columns,
      getUsers,
      navigateToItem,
      renderKey,
      createUser,
    }
  },
}
</script>
