<template>
  <div>
    <v-btn v-if="isAdmin" class="mb-4" rounded="lg" @click="createDepartment">
      <v-icon class="mr-1">mdi-plus-circle</v-icon>
      <span>Створити департамент</span>
    </v-btn>

    <div class="d-flex gap-3 mb-2">
      <v-btn color="secondary" small @click="expandAll">
        <span class="mr-1">Розгорнути</span>
        <v-icon color="white">{{ 'mdi-plus' }}</v-icon>
      </v-btn>
      <v-btn color="secondary" small @click="collapseAll">
        <span class="mr-1">Згорути</span>
        <v-icon color="white">{{ 'mdi-minus' }}</v-icon>
      </v-btn>
    </div>
    <div v-if="!loading">
      <TreeTable
        v-model:expanded="expanded"
        :headers="headers"
        :items="formattedCompanyStructure"
        item-value="id">
        <template #item.name="{ item, props }">
          <div class="d-flex align-center gap-3" v-bind="props">
            <span v-if="item.type === 'supervisor'" class="disabled">
              Супервайзер
            </span>
            <span
              :class="{
                'font-weight-bold': item.type === 'department',
                link: item.type !== 'department',
              }"
              @click="navigateToUser(item)">
              {{ item.name }}
            </span>
          </div>
        </template>
        <template #item.actions="{ item, props }">
          <div
            v-if="item.type === 'department'"
            style="width: min-content"
            v-bind="props">
            <TableActions
              :actions="[
                {
                  label: 'Редагувати',
                  icon: 'mdi-pencil',
                  action: () => updateDepartment(item),
                  disabled: !isAdmin,
                },
                {
                  label: 'Видалити департамент',
                  icon: 'mdi-delete',
                  action: () => deleteDepartment(item),
                  disabled: !isAdmin,
                },
              ]" />
          </div>
        </template>
      </TreeTable>
    </div>
    <div v-else>
      <Skeleton />
    </div>
  </div>
</template>

<script>
import TreeTable from '@/components/tree-table/Index.vue'
import { useCompanyStructure } from './companyStructure'
import TableActions from '@/components/TableActions.vue'
import Skeleton from '@/components/Skeleton.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'CompanyStructure',
  components: { TableActions, TreeTable, Skeleton },
  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.state.user.userData.roleId === 1)

    return {
      isAdmin,
      ...useCompanyStructure(),
    }
  },
}
</script>

<style lang="scss" scoped></style>
