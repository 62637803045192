<template>
  <div>
    <b-form :submit="pushUser" hide-action-buttons>
      <b-autocomplete
        v-model="selectedUser"
        :error-messages="getErrorMessages(v$.users)"
        :items="$directory.get('userList')"
        :loading="$loading.isLoading('userList')"
        class="mb-1"
        item-title="name"
        item-value="id"
        label="Співробітник"
        placeholder="Оберіть зі списку"
        return-object
        @blur="v$.users.$touch()"
        @focus="$directory.fill('userList')">
        <template #append>
          <v-btn type="submit">Додати</v-btn>
        </template>
      </b-autocomplete>
    </b-form>

    <v-card
      v-for="user in documentData.users"
      :key="user.id"
      class="mb-3"
      variant="tonal">
      <v-card-text>
        <div class="d-flex align-center justify-space-between">
          <div style="font-size: 1rem">
            <div class="text-disabled mb-3">{{ user.post }}</div>
            <div>{{ user.name }}</div>
          </div>
          <div class="d-flex align-center gap-3">
            <v-switch
              v-model="user.isSignature"
              color="primary"
              hide-details
              label="Підписує"></v-switch>
            <v-btn
              color="error"
              density="compact"
              icon="mdi-delete"
              rounded="circle"
              variant="outlined"
              @click="removeUser(user.id)"></v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { PropType, reactive, ref } from 'vue'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import { axios } from '@/plugins/index.js'
import { urlDocumentSendToSign } from '@/utils/urls'
import { Dialog } from 'best-modules/plugins/dialog/types'
import { useRouter } from 'vue-router'
import { BForm } from 'best-modules/components'
import { pick } from 'lodash'

export default {
  name: 'DocumentSendToSign',
  components: { BForm },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'DocumentSendToSign'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const router = useRouter()
    const documentData = reactive({
      users: [],
    })

    const selectedUser = ref()

    const rules = {
      users: { required: required, minLength: minLength(1) },
    }

    const v$ = useVuelidate(rules, documentData)

    const pushUser = () => {
      if (
        selectedUser.value &&
        !documentData.users.some(u => u.id === selectedUser.value.id)
      ) {
        documentData.users.push({
          ...selectedUser.value,
          isSignature: false,
        })
        selectedUser.value = null
      }
    }
    const removeUser = (userId: number) => {
      documentData.users = documentData.users.filter(u => u.id !== userId)
    }

    const submit = () => {
      const requestObj = {
        users: documentData.users.map(u => pick(u, ['id', 'isSignature'])),
      }
      return axios
        .post(
          urlDocumentSendToSign(props.dialog.dialogData.documentId),
          requestObj
        )
        .then(res => {
          return router.push({
            name: 'document-signature-single',
            params: { id: res.data.id },
          })
        })
    }

    return { documentData, v$, pushUser, removeUser, selectedUser, submit }
  },
}
</script>

<style lang="scss" scoped></style>
