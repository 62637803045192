<template>
  <div class="d-flex gap-2 justify-end">
    <v-btn
      v-if="!hideCancel"
      :disabled="disabled || $loading.isLoading('submitButtonsSubmit')"
      :label="cancelLabel"
      :loading="loading || $loading.isLoading('submitButtonsCancel')"
      class="rounded-lg"
      color="black"
      size="large"
      variant="text"
      width="140px"
      @click="onCancel"
      >{{ cancelLabel }}
    </v-btn>
    <v-btn
      :disabled="disabled || $loading.isLoading('submitButtonsCancel')"
      :label="submitLabel"
      :loading="loading || $loading.isLoading('submitButtonsSubmit')"
      rounded="lg"
      size="large"
      width="140px"
      @click="onSubmit"
      >{{ submitLabel }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import { inject, PropType } from 'vue'
import { handleAsync } from 'best-modules/plugins'

export default {
  emits: ['submit', 'cancel'],
  props: {
    submitLabel: { type: String as PropType<string>, default: 'Підтвердити' },
    cancelLabel: { type: String as PropType<string>, default: 'Відмінити' },
    defaultCancel: { type: Boolean as PropType<boolean>, default: true },
    disabled: { type: Boolean as PropType<boolean> },
    loading: { type: Boolean as PropType<boolean> },
    compact: { type: Boolean as PropType<boolean> },
    submit: { type: Function as PropType<() => Promise<any> | any> },
    cancel: { type: Function as PropType<() => Promise<any> | any> },
    hideCancel: { type: Boolean as PropType<boolean> },
  },
  setup(props, { emit }) {
    const dialogRef = inject('dialogRef', { value: { close: v => v } })

    const defaultCancel = (): void => dialogRef.value.close(null)

    const onCancel = async (): Promise<void> => {
      props.defaultCancel && defaultCancel()
      emit('cancel')
      if (typeof props.cancel === 'function') {
        await handleAsync('submitButtonsCancel', async () => {
          await props.cancel()
        })
      }
    }

    const onSubmit = async () => {
      emit('submit')
      if (typeof props.submit === 'function') {
        await handleAsync('submitButtonsSubmit', async () => {
          await props.submit()
        })
      }
    }
    return { onCancel, onSubmit }
  },
}
</script>
