<template>
  <div>
    <v-data-table
      v-if="flaw.recommendations.length"
      :headers="auditRecommendationListHeaders"
      :hide-default-footer="flaw.recommendations.length <= 10"
      :items="flaw.recommendations"
      class="mb-3"
      color="red"
      density="compact"
      item-value="id">
      <template #item.status="{ item }">
        <v-chip color="blue-grey-lighten-5" size="small" variant="flat"
          ><span style="color: #8e92bc">{{ item.status?.name || '--- ' }}</span>
        </v-chip>
      </template>
      <template #item.task="{ item }">
        <span
          v-tooltip:bottom="item.task.status.name"
          class="link"
          @click="
            $router.push({
              name: 'single-task',
              params: { id: item.task.id },
            })
          ">
          {{ item.task.topic }}
        </span>
      </template>
      <template #item.executor="{ item }">
        <span>
          {{
            getPersonName(item.task.executor, {
              keyNames: { surname: 'secondName' },
            })
          }}
        </span>
      </template>
      <template #item.actions="{ item }">
        <b-action-menu
          :actions="[
            {
              title: 'Проставити статус',
              icon: 'mdi-set-split',
              action: () => setRecommendationStatus(item.id),
              disabled: audit.statusId !== 2,
            },
          ]"></b-action-menu>
      </template>
    </v-data-table>
    <div v-else class="text-center text-disabled">Рекомендації відсутні</div>
  </div>
</template>

<script lang="ts">
import { auditRecommendationListHeaders } from '../headers'
import { computed, inject } from 'vue'
import { auditKey, updateAuditKey } from '@/pages/audit/single/injectionKeys'
import { openDialog } from '@/dialog'
import { BActionMenu } from 'best-modules/components'
import { getPersonName } from 'best-modules/utils/helpers'

export default {
  name: 'AuditRecommendationList',
  components: { BActionMenu },
  props: {
    flawId: {
      type: Number,
    },
  },
  data: () => {
    return {
      auditRecommendationListHeaders,
    }
  },
  methods: { getPersonName },
  setup(props) {
    const updateAudit = inject(updateAuditKey)
    const audit = computed({
      get: () => inject(auditKey),
      set: v => updateAudit(v),
    })

    const flaw = computed({
      get: () => audit.value.flaws.find(f => f.id === props.flawId),
      set: v => {
        const updatedFlaw = audit.value.flaws.find(f => f.id === props.flawId)

        Object.assign(updatedFlaw, v)
      },
    })

    const createAuditRecommendation = () => {
      openDialog({
        name: 'AuditRecommendation',
        dialogData: {
          auditFlawId: props.flawId,
        },
        params: {
          cb: recommendation => {
            flaw.value.recommendations.push(recommendation)
          },
        },
      })
    }

    const setRecommendationStatus = (recommendationId: number) => {
      openDialog({
        name: 'AuditRecommendationSetStatus',
        dialogData: { recommendationId },
        params: {
          cb: recommendation => {
            flaw.value.recommendations = flaw.value.recommendations.map(r => {
              if (r.id === recommendation.id) {
                return recommendation
              } else {
                return r
              }
            })
          },
        },
      })
    }

    return { audit, flaw, createAuditRecommendation, setRecommendationStatus }
  },
}
</script>

<style lang="scss" scoped></style>
