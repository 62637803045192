<template>
  <div>
    <b-input
      v-model="cancelText"
      :error-messages="getErrorMessages(v$.cancelText)"
      :loading="$loading.isLoading('signatureBreak')"
      label="Коментар"
      placeholder="Введіть текст"></b-input>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios/index.js'
import { urlSignatureBreak } from '@/utils/urls'
import { handleAsync } from 'best-modules/plugins'
import { getErrorMessages } from 'best-modules/utils'

export default {
  name: 'DocumentCancel',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const cancelText = ref<string>('')
    const rules = {
      cancelText: { required: required },
    }
    const v$ = useVuelidate(rules, { cancelText })

    const submit = () => {
      return handleAsync('signatureBreak', () => {
        return axios
          .post(urlSignatureBreak(props.dialog.dialogData.documentId), {
            text: cancelText.value,
          })
          .then(res => res.data)
      })
    }

    return { cancelText, v$, submit }
  },
}
</script>

<style lang="scss" scoped></style>
