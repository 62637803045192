<template>
  <div>
    <v-card>
      <v-card-text>
        <v-tabs hide-slider>
          <v-tab
            :to="{
              name: 'contractor-general-info',
              params: { id: $route.params.id },
            }"
            replace>
            Загальна інформація
          </v-tab>
          <v-tab
            :to="{
              name: 'contractor-connections',
              params: { id: $route.params.id },
            }"
            replace>
            Зв'язки
          </v-tab>
        </v-tabs>
        <Skeleton v-if="$loading.isLoading('getContractor')" />
        <RouterView
          v-else
          v-model:contractor="contractor"
          :update-contractor="updateContractor"
          :v$="v$" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import { axios, useBreadcrumb, useRoute } from '@/plugins/index.js'
import { urlContractorSingle, urlContractorUpdate } from '@/utils/urls.js'
import { computed } from 'vue'
import { cachedObject } from 'best-modules/utils'
import { handleAsync } from 'best-modules/plugins'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  components: { Skeleton },
  setup() {
    const route = useRoute()
    const breadcrumb = useBreadcrumb()
    const contractor = cachedObject({})
    contractor.$setIgnore('addresses', 'emails', 'phones')
    const contractorId = computed(() => route.params.id)

    const rules = {
      name: { required },
      code: { required },
      typeId: { required },
      shortName: { required },
    }

    const v$ = useVuelidate(rules, contractor)

    const setContractor = obj => {
      contractor.$set(obj)
      breadcrumb.set([
        {
          title: 'Контрагенти',
          to: { name: 'contractors' },
          index: 0,
        },
        {
          title: obj.name,
          index: 1,
        },
      ])
    }

    const getContractor = () => {
      return handleAsync('getContractor', () => {
        return axios.get(urlContractorSingle(contractorId.value)).then(res => {
          setContractor(res.data)
        })
      })
    }

    const updateContractor = () => {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        return axios
          .post(urlContractorUpdate(contractorId.value), contractor.$object)
          .then(res => {
            setContractor(res.data)
          })
      }
    }

    getContractor()

    return { contractor, updateContractor, v$ }
  },
}
</script>

<style lang="scss" scoped></style>
