import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import axios from './plugins/axios/index.js'
import vuetify from '@/plugins/vuetify'
import { breadcrumbPlugin } from './plugins/breadcrumb.js'
import { vMaska } from 'maska'
import BestModules from 'best-modules'
import BaseTextarea from '@/components/inputs/BaseTextarea.vue'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import FilesUploader from '@/components/filesUploader/FilesUploader.vue'
import BaseImage from '@/components/BaseImage.vue'
import DirectoryPlugin from '@/directory'
import 'best-modules/assets/base.scss'
import * as Sentry from '@sentry/vue'

import './assets/styles/main.scss'
import { urlUploadFiles } from '@/utils/urls.js'
import 'vue-router/dist/vue-router'
import ToggleFilterButton from '@/plugins/best-modules/components/ToggleFilterButton.vue'

console.log('VERSION', import.meta.env.PACKAGE_VERSION)

const app = createApp(App)

// global registration UI components
app.component('BaseTextarea', BaseTextarea)
app.component('BaseDatePicker', BaseDatePicker)
app.component('BaseImage', BaseImage)
app.component('FilesUploader', FilesUploader)

app.directive('mask', vMaska)

// set custom methods for global Classes
/* TODO */
// @ts-ignore
Array.prototype.id = function (id, defaultValue = null) {
  return this.find(el => el.id === id) || defaultValue
}

app.use(router)
app.use(store)
app.use(vuetify)
app.use(breadcrumbPlugin)
app.use(DirectoryPlugin)
app.use(BestModules, {
  axios,
  urls: {
    urlUploadFiles: urlUploadFiles,
  },
  templates: {
    BServerFilter: {
      toggleButton: {
        component: ToggleFilterButton,
      },
    },
  },
})

Sentry.init({
  app,
  release: `intime@${import.meta.env.PACKAGE_VERSION}`,
  enabled: true,
  dsn: 'https://81744d8cc830339cbf3c9e0f4df1b3b0@o4507386165919744.ingest.de.sentry.io/4507411927924816',
  maxBreadcrumbs: 20,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  initialScope: scope => {
    scope.setUser(store.state.user)
    return scope
  },

  // transactions
  enableTracing: true,
  tracePropagationTargets: ['https://intime.bestleasing.com.ua'],

  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  async beforeSend(event, hint) {
    let originalException

    try {
      if (hint.originalException instanceof Promise) {
        originalException = await hint.originalException
      }
      if (
        typeof hint.originalException === 'object' &&
        hint.originalException !== null
      ) {
        originalException = hint.originalException
      }
    } catch (e) {
      originalException = e
    }

    const isServerError =
      typeof originalException === 'object' &&
      !!originalException.isAxios &&
      !!originalException.response &&
      !!originalException.response.status
    // && originalException.response.status !== 422
    const isHandledError = originalException.throwError === false

    console.groupCollapsed(
      `sentry - before send, isSend - ${!isServerError && !isHandledError}`
    )
    console.log('event', event)
    console.log('hint', hint)
    console.log('originalException', originalException)
    console.log('isServerError', isServerError)
    console.log('isHandledError', isHandledError)
    console.groupEnd()

    if (location.origin.includes('localhost')) {
      return null
    }
    if (isServerError || isHandledError) {
      return null
    } else {
      return event
    }
  },
})

app.mount('#app')
