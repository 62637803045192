<template>
  <div>
    <BaseTextarea
      v-model="deactivateData.deactivateComment"
      :error-messages="getErrorMessages(v$.deactivateComment)"
      label="Причина"
      placeholder="Введіть причину"
      @blur="v$.deactivateComment.$touch()"></BaseTextarea>
    <b-autocomplete
      v-model="deactivateData.actualOrder"
      :items="orderList"
      :loading="$loading.isLoading('searchOrders')"
      clear-on-select
      item-title="name"
      item-value="id"
      label="Актуальний наказ"
      no-filter
      placeholder="обірть наказ"
      return-object
      @update:search="search"></b-autocomplete>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { urlDeactivateOrder, urlOrderList } from '@/utils/urls.js'
import axios from '@/plugins/axios/index.js'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { handleAsync } from 'best-modules/plugins'
import { getErrorMessages } from '@/utils/helpers.js'
import { debounce } from 'lodash'

export default {
  name: 'OrderDeactivate',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const deactivateData = reactive({
      deactivateComment: null,
      actualOrder: null,
    })
    const rules = {
      deactivateComment: { required },
    }
    const v$ = useVuelidate(rules, deactivateData)

    const orderList = ref([])
    const search = debounce(event => {
      handleAsync('searchOrders', () => {
        if (event && event !== deactivateData.actualOrder?.name) {
          return axios
            .get(urlOrderList({ filters: { search: event } }))
            .then(res => (orderList.value = res.data.data))
        } else {
          orderList.value = [deactivateData.actualOrder]
        }
      })
    }, 300)

    const submit = () => {
      return handleAsync('deactivateOrder', () => {
        return axios.post(urlDeactivateOrder(props.dialog.dialogData.orderId), {
          deactivateComment: deactivateData.deactivateComment,
          actualOrderId: deactivateData?.actualOrder?.id || null,
        })
      })
    }

    return {
      deactivateData,
      v$,
      submit,
      orderList,
      search,
    }
  },
}
</script>
