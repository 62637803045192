import { computed, inject, ref } from 'vue'
import { axios, useBreadcrumb, useRoute } from '@/plugins/index.js'
import {
  urlDocumentAgreementList,
  urlDocumentSignatureList,
} from '@/utils/urls.js'
import { navigateToItem } from '@/utils/helpers.js'
import { openDialog } from '@/dialog/index.ts'
import { documentActionKey } from './injectionKeys'
import { PaginationList } from 'best-modules/components/server-table/types'
import { DataTableHeader } from 'best-modules/components/index'
import { Document } from '@/utils/types/document'

export function useDocumentList() {
  const renderKey = ref(1)
  const route = useRoute()
  const documentAction = inject(documentActionKey)
  const breadcrumb = useBreadcrumb()
  breadcrumb.set([], { type: 'replace' })

  // eslint-disable-next-line vue/return-in-computed-property
  const url = computed(() => {
    switch (route.name) {
      case 'document-signature-notes':
        return (query = {}) =>
          urlDocumentSignatureList({
            ...query,
            statusId: 1,
          })
      case 'document-signature-all':
        return (query = {}) =>
          urlDocumentSignatureList({
            ...query,
            statusId: 2, // done
          })
      case 'document-signature-signed':
        return (query = {}) =>
          urlDocumentSignatureList({
            ...query,
            statusId: 3, // done
          })
      case 'document-signature-canceled':
        return (query = {}) =>
          urlDocumentSignatureList({
            ...query,
            statusId: 4, // done
          })
      case 'document-agreement-new':
        return (query = {}) =>
          urlDocumentAgreementList({
            ...query,
            statusId: 1,
          })
      case 'document-agreement-revision':
        return (query = {}) =>
          urlDocumentAgreementList({
            ...query,
            statusId: 2,
          })
      case 'document-agreement-me':
        return (query = {}) =>
          urlDocumentAgreementList({
            ...query,
            statusId: 3,
          })
      case 'document-agreement-agreed':
        return (query = {}) =>
          urlDocumentAgreementList({
            ...query,
            statusId: 4,
          })
    }
  })

  const documentListHeaders: DataTableHeader[] = [
    {
      key: 'created_at',
      title: 'Дата',
      align: 'start',
      sortable: false,
    },
    {
      key: 'name',
      title: 'Назва',
      align: 'start',
      sortable: false,
    },
    {
      key: 'status',
      title: 'Статус',
      align: 'start',
      sortable: false,
    },
    {
      key: 'number',
      title: 'Номер',
      align: 'start',
      sortable: false,
    },
    {
      key: 'type',
      title: 'Тип',
      align: 'start',
      sortable: false,
      minWidth: '140px',
    },
    {
      key: 'author',
      title: 'Автор',
      sortable: false,
    },
    {
      key: 'contractors',
      title: 'Контрагенти',
      align: 'center',
      sortable: false,
    },
  ]

  const getDocuments = (query): Promise<PaginationList<Document>> => {
    return axios.get(url.value(query)).then(res => res.data)
  }

  const createDocument = () => {
    openDialog({
      name: 'Document',
      action: 'create',
      dialogData: {
        documentAction: documentAction.value,
      },
      params: {
        cb: document => {
          navigateToItem(`document-${documentAction.value}-single`, document.id)
        },
        submitLabel: 'Створити',
      },
    })
  }

  return {
    renderKey,
    navigateToItem,
    createDocument,
    getDocuments,
    documentListHeaders,
  }
}
