<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <span class="input-label">Назва</span>
        <b-input
          v-model="flaw.text"
          :error-messages="getErrorMessages(v$.text)"
          hide-details
          placeholder="Введіть значення"
          @blur="v$.text.$touch()">
        </b-input>
      </v-col>
      <v-col cols="12" md="12">
        <span class="input-label">Опис</span>
        <base-textarea
          v-model="flaw.factorFlaw"
          :error-messages="getErrorMessages(v$.factorFlaw)"
          hide-details
          placeholder="Введіть значення"
          @blur="v$.factorFlaw.$touch()">
        </base-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from '@/dialog'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios/index.js'
import { urlAuditFlawCreate, urlAuditFlawUpdate } from '@/utils/urls'
import { getErrorMessages } from 'best-modules/utils'
import { AuditFlaw } from '@/utils/types/audit'

export default {
  name: 'AuditFlaw',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const flaw = reactive<AuditFlaw>({
      auditId: props.dialog.dialogData.auditId,
      text: null,
      factorFlaw: null,
    } as AuditFlaw)
    if (props.dialog.action === 'update') {
      Object.assign(flaw, props.dialog.dialogData.flaw)
    }
    const rules = {
      text: { required: required },
      factorFlaw: { required: required },
    }
    const v$ = useVuelidate(rules, flaw)

    const submit = () => {
      if (props.dialog.action === 'create') {
        return axios.post(urlAuditFlawCreate(), flaw)
      }
      if (props.dialog.action === 'update') {
        return axios.post(urlAuditFlawUpdate(flaw.id), flaw)
      }
    }

    return { flaw, v$, submit }
  },
}
</script>

<style lang="scss" scoped></style>
